<template>
  <div class="boxPadding">
    <!-- 타이틀 | 카운트 | 서치 -->
    <div class="inner-search search-view mar_to0">
      <div class="ly-gp02" style="padding-left: 5px; padding-right: 5px">
        <div ref="boxTitle" @click="refreshTreeList">{{ title }}</div>
        <div>
          총 <span class="col_blue2">{{ countSelectedItem }}</span
          >개
        </div>
      </div>

      <!-- 트리 리스트 -->
      <div class="depth-tree-box">
        <DxTreeList
          ref="dxTreeList"
          height="380px"
          key-expr="NODE_ID"
          parent-id-expr="PARENT_ID"
          :root-value="-1"
          :disabled="disabled"
          :show-borders="true"
          :show-row-lines="true"
          :data-source="treeDatas"
          :column-auto-width="true"
          :show-column-lines="false"
          :show-column-headers="false"
          no-data-text="데이터가 존재하지 않습니다."
          @cell-click="handleClick"
          @selectionChanged="onChangeCheckedItem(false)"
        >
          <DxSearchPanel :visible="true" :highlightSearchText="true" placeholder="검색어를 입력해주세요." />
          <DxSelection :recursive="treeOption.recursive" :mode="treeOption.mode" />
          <DxScrolling mode="virtual" rowRenderingMode="virtual" :preloadEnabled="true" :useNative="true" />
          <DxColumn data-field="TEXT" />
        </DxTreeList>
      </div>
    </div>

    <!-- 전체 선택|해제 -->
    <div class="alR">
      <button class="txt on" @click="onClickAllCheckBtn('select')">전체선택</button>
      /
      <button class="txt" @click="onClickAllCheckBtn('deselect')">전체해제</button>
    </div>
  </div>
</template>
<script>
  import { DxTreeList, DxSelection, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/tree-list';

  const $ = window.$;
  export default {
    components: {
      DxTreeList,
      DxScrolling,
      DxColumn,
      DxSelection,
      DxSearchPanel,
    },
    watch: {
      selectedKeys(values) {
        if (this.selectedKeys.length > 0) {
          this.selectRows(values);
        }
      },
    },
    props: {
      title: {
        type: String,
        default: () => '',
      },
      treeDatas: {
        type: Array,
        default: () => [],
      },
      dataName: {
        type: String,
        default: () => undefined,
      },
      nextDataName: {
        type: String,
        default: () => undefined,
      },
      setCheckedItems: {
        type: Function,
        default: () => undefined,
      },
      disabled: {
        type: Boolean,
        default: true,
      },
      firstBoxHiding: {
        type: Boolean,
        default: false,
      },
      selectedCategory: {
        type: String,
        default: () => 'default_cti',
      },
    },
    data() {
      return {
        treeOption: {
          recursive: true,
          stylingMode: 'outlined',
          mode: 'multiple',
        },
        keyword: '',
        selectedKeys: [],
      };
    },
    computed: {
      countSelectedItem() {
        return this.treeDatas.filter(v => !v.isNav).length.toLocaleString('ko-KR');
      },
    },
    updated() {
      if (this.selectedKeys.length > 0) {
        this.selectRows(this.selectedKeys);
      }
    },
    methods: {
      //Func
      getInstance() {
        return this.$refs['dxTreeList'].instance;
      },
      refreshTreeList() {
        this.getInstance().refresh();
      },
      selectRows(values) {
        this.getInstance().selectRows(values, true);
      },
      deselectRows(values) {
        this.getInstance().deselectRows(values, true);
      },
      onClickAllCheckBtn(funcName) {
        if (!['select', 'deselect'].includes(funcName)) return false;
        // select, deselect
        const dataGrid = this.getInstance();
        const searchText = dataGrid.option('searchPanel.text');
        if (searchText !== '') {
          const filterDatas = this.treeDatas.reduce((acc, cur) => {
            if (cur.TEXT.includes(searchText)) acc.push(cur.VALUE);
            return acc;
          }, []);

          this[`${funcName}Rows`](filterDatas);
        } else {
          dataGrid[`${funcName}All`]();
          if ('deselect' === funcName) {
            this.$emit('clearChekcedItems');
          }
        }
        this.onChangeCheckedItem(true);
      },
      handleTreeListContentReady(e) {
        const allNodes = e.component.getVisibleRows();
        allNodes.forEach(node => {
          e.component.expandRow(node.key);
        });
      },
      handleClick(e) {
        if (e.event.target.className !== 'dx-checkbox-icon') return;
        this.onChangeCheckedItem(true);
      },
      onChangeCheckedItem(isClick) {
        const checkedDatas = this.getInstance().getSelectedRowsData('all');
        const checkedKeys = checkedDatas.map(v => v.VALUE);
        const checkedRealDatas = this.treeDatas.filter(v => !v.isNav && checkedKeys.includes(v.VALUE));

        try {
          this.$emit('setCheckedItems', this.dataName, this.nextDataName, checkedRealDatas, isClick);
        } catch (e) {
          console.error('onChangeCheckedItem', e);
        }
      },
      getSelectedItems() {
        return this.getInstance().getSelectedRowsData('all');
      },
    },
    mounted() {},
  };
</script>
<style scoped>
  button.txt {
    color: dodgerblue;
  }

  button.on {
    color: #00c3c8;
  }

  .boxPadding {
    padding: 12px 5px;
  }

  .depth-tree-box {
    margin-bottom: 10px;
    padding: 1px 0 0 0;
    border: 0.5px solid #ececec;
  }
</style>
<!-- Global -->
<style>
  .depth-tree-box .dx-datagrid-header-panel {
    margin: 0;
    padding: 10px;
  }

  .depth-tree-box .dx-toolbar .dx-texteditor {
    width: 100% !important;
  }

  .depth-tree-box .dx-toolbar .dx-toolbar-after {
    left: -10px;
    padding-left: 0px;
    width: 95% !important;
  }

  .depth-tree-box .dx-placeholder {
    left: 16px !important;
  }
</style>
