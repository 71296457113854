export default {
  copyright: 'ⓒ 2023. ECS Telecom all right reserved.',
  defaultColors: {
    'Dark Slate': '#334155',
    'Deep Sea Green': '#006565',
    'Royal Blue': '#2A3BAB',
    'Ocean Blue': '#164371',
    'Deep Cherry': '#851012',
    'Silent Grey': '#B7B7B2',
    'Bright Red': '#EF3F3F',
    'Deep Sky Blue': '#004C9D',
    'Bright Sky Blue': '#0092D7',
    'Mocha Brown': '#6E6053',
    'Vivid Orange': '#EA5705',
    'Bright Gold': '#F8A70C',
    'Bright Sun': '#FAB809',
  },
  imageFileExtensions: ['.jpg', '.jpeg', '.gif', '.png'],
  fontSizes: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
  menuIcons: [
    {
      'label': '모니터링',
      'value': 'monitoring',
      'src': require('@/assets/images/monitoring_01.png'),
    },
    {
      'label': '환경설정',
      'value': 'preferences',
      'src': require('@/assets/images/preferences_01.png'),
    },
    {
      'label': '운영관리',
      'value': 'operationmng',
      'src': require('@/assets/images/operationmng_01.png'),
    },
    {
      'label': '보고서',
      'value': 'report',
      'src': require('@/assets/images/report_01.png'),
    },
    {
      'label': '대시보드',
      'value': 'dashboards',
      'src': require('@/assets/images/dashboards_01.png'),
    },
    {
      'label': '컴퓨터1',
      'value': 'computer1',
      'src': require('@/assets/images/computer1_01.png'),
    },
    {
      'label': '컴퓨터2',
      'value': 'computer2',
      'src': require('@/assets/images/computer2_01.png'),
    },
    {
      'label': '전화기2',
      'value': 'landline2',
      'src': require('@/assets/images/landline2_01.png'),
    },
    {
      'label': '의자1',
      'value': 'free_seat1',
      'src': require('@/assets/images/free_seat1_01.png'),
    },
    {
      'label': '휴대폰1',
      'value': 'phone1',
      'src': require('@/assets/images/phone1_01.png'),
    },
    {
      'label': '휴대폰2',
      'value': 'phone2',
      'src': require('@/assets/images/phone2_01.png'),
    },
    {
      'label': '상담원1',
      'value': 'consultant1',
      'src': require('@/assets/images/consultant1_01.png'),
    },
    {
      'label': '이동1',
      'value': 'move1',
      'src': require('@/assets/images/move1_01.png'),
    },
    {
      'label': '전화기1',
      'value': 'landline1',
      'src': require('@/assets/images/landline1_01.png'),
    },
    {
      'label': '전화기3',
      'value': 'landline3',
      'src': require('@/assets/images/landline3_01.png'),
    }
  ],
};

