export const getters = {
	/**
	 * 사용자의 액세스 토큰 가져오기
	 * @param state
	 * @return {null}
	 */
	getAccessToken(state) {
		return state.accessToken;
	},
	/**
	 * 사용자의 리프레시 토큰 가져오기
	 * @param state
	 * @return {null}
	 */
	getRefreshToken(state) {
		return state.refreshToken;
	},
	/**
	 * 라우팅 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것 <br>
	 *
	 * 응답값 <br>
	 * { <br>
	 *  "host": "http://localhost:8081/esp/v1", <br>
	 *  "path": "/action", <br>
	 *  "method": "post", <br>
	 *  "timeOut": 5000, <br>
	 *  "actionNm": "ACTION_NAME" <br>
	 * }, <br>
	 * @param state
	 * @return {*|*[]}
	 */
	getRoutingInfos(state) {
		return state && state.routingInfos ? state.routingInfos : [];
	},
	/**
	 * 메뉴 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getMenuList(state) {
		return state && state.menuList ? state.menuList : [];
	},
	/**
	 * 공통코드 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getCodeList(state) {
		return state && state.codeList ? state.codeList : [];
	},
	/**
	 * 로그인 ID 가져오기
	 * @param state
	 * @return {null}
	 */
	getLoginId(state) {
		return state.loginId;
	},
	/**
	 * 로그인 이름 가져오기
	 * @param state
	 * @return {null}
	 */
	getLoginNm(state) {
		return state.loginNm;
	},
	/**
	 * 사이트 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getSiteList(state) {
		return state && state.siteList ? state.siteList : [];
	},
	/**
	 * 테넌트 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getTenantList(state) {
		return state && state.tenantList ? state.tenantList : [];
	},
	/**
	 * 로그인 유지 여부 가져오기 <br>
	 * @param state
	 * @return {string}
	 */
	getIsKeepLogin(state) {
		return state && state.isKeepLogin ? state.isKeepLogin : 'N';
	},
	/**
	 * 공휴일 목록 가져오기 <br>
	 * !!조건문 생성시 .length를 사용 할 것
	 * @param state
	 * @return {*|*[]}
	 */
	getHolidayList(state) {
		return state && state.holidayList ? state.holidayList : [];
	},
	/**
	 * 솔루션 타입 가져오기
	 * @param state
	 * @return {null}
	 */
	getSolutionType(state) {
		return state.solutionType;
	},
	getThemeSetting(state) {
		return state && state.themeSetting ? state.themeSetting : {};
	},
	/**
	 * 마이레이어 레이아웃 표시 여부 가져오기
	 * @param state
	 * @return {boolean}
	 */
	getIsShowMyLayerLayout(state) {
		return state && state.isShowMyLayerLayout ? state.isShowMyLayerLayout : false;
	},
	/**
	 * 모바일 여부 가져오기
	 * @param state
	 * @return {*|boolean}
	 */
	getIsMobile(state) {
		return state && state.isMobile ? state.isMobile : false;
	},
	/**
	 * 시스템 설정 목록 가져오기 <br>
	 * @param state
	 * @return {*|*[]}
	 */
	getSystemList(state) {
		return state && state.systemList ? state.systemList : [];
	},
	/**
	 * 암호화 타입 가져오기
	 * @param state
	 * @return {null}
	 */
	getEncryptionType(state) {
		return state.encryptionType;
	},
	/**
	 * 권한 가져오기
	 * @param state
	 * @return {null}
	 */
	getAuthId(state) {
		return state.authId;
	},
	/**
	 * 사용자 정보 가져오기
	 *
	 * @param state
	 * @returns {null}
	 */
	getUserInfo(state) {
		return state.userInfo;
	},
	/**
	 * 즐겨찾기 리스트
	 *
	 * @param state
	 * @returns {*[]}
	 */
	getFavorite(state) {
		return state?.favorite || [];
	},
	/**
	 * 사용자 상태 가져오기
	 *
	 * @param state
	 * @return {*}
	 */
	getMemberState(state) {
		return state.memberState;
	},
	/**
	 * 관리자 여부 가져오기
	 *
	 * @param state
	 * @return {*}
	 */
	getIsAdminUser(state) {
		return state.isAdminUser;
	},
	/**
	 * 사용자 메인 페이지 가져오기
	 *
	 * @param state
	 * @return {string|null}
	 */
	getUserMainPage(state) {
		return state.userMainPage;
	},
};
