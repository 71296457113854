import { espApi } from '@/api';
import router from '@/router';

/**
 * 로그인 정보 설정
 * @param commit
 * @param res
 */
const setStore = async (commit, res) => {
	const promises = [
		commit('setLoginId', res.data.data.loginId),
		commit('setLoginNm', res.data.data.loginNm),
		commit('setAccessToken', res.data.data.accessToken),
		commit('setRefreshToken', res.data.data.refreshToken),
		commit('setIsKeepLogin', res.data.data.isKeepLogin),
		commit('setUserInfo', res.data.data.userView),
		commit('setMemberState', res.data.data.memberState),
		commit('setPersistedAccessToken', res.data.data.accessToken),
  ];
	await Promise.all(promises);
};

/**
 * 에러 핸들러
 * @param error
 * @return {*|e.Response|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse}
 */
const errorHandle = error => {
	if (error.response) {
		// 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
		return error.response;
	} else if (error.request) {
		// 요청이 이루어 졌으나 응답을 받지 못했습니다.
		return error.request;
	} else {
		// 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
		return error.message;
	}
};

/**
 * 로그인
 * @param commit
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const LOGIN = async ({ commit }, payload) => {
	try {
		const res = await espApi.getLogin(payload);
		if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
			await setStore(commit, res);
		}
		return res;
	} catch (error) {
		return errorHandle(error);
	}
};

/**
 * 다른 디바이스에서 로그인 여부 체크(중복 로그인 체크)
 * @param commit
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const LOGIN_ANOTHER_CHECK = async ({ commit }, payload) => {
	try {
		const res = await espApi.getLoginAnotherCheck(payload);
		if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
			await setStore(commit, res);
		}
		return res;
	} catch (error) {
		return errorHandle(error);
	}
};

/**
 * 로그아웃
 * @param commit
 * @param payload
 * @return {Promise<AxiosResponse<any>>}
 * @constructor
 */
export const LOGOUT = async ({ commit }, payload) => {
	try {
		await espApi.getLogout(payload);
		await router.push({
			name: 'Login',
			params: {
				status: payload.status,
			},
		});
	} catch (error) {
		await router.push({
			name: 'Login',
			params: {
				status: payload.status,
			},
		});
	}
};

/**
 * AES256 암호화된 로그인 ID가 있을 경우 다이렉트 로그인
 * With. KB증권
 * @param commit
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const LOGIN_DIRECT = async ({ commit }, payload) => {
	try {
		const res = await espApi.getDirectLogin(payload);
		if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
			await setStore(commit, res);
		}
		return res;
	} catch (error) {
		return errorHandle(error);
	}
};
