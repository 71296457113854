import { EventBus } from '@/event-bus';
import { ucApi } from '@/api';

/**
 * routing 정보 조회
 * @param state - store state
 * @param payload
 * @return {T}
 */
const getRoutingInfo = ({ state }, payload) => {
	let routingInfo = state.routingInfos.find(d => d.actionNm === payload.actionname);

	// actionname이 stor routingInfos 에 없는 경우( 예) 미표시된 API URL )
	if (payload.actionname !== null && payload.actionname !== undefined && routingInfo === undefined) {
		const message = `
					캐시 새로고침 또는 로그아웃 후 재시도 하세요. </br>
					(오류 재발시 관리자에게 문의하세요.) </br>
					not found action name -> "${payload.actionname}"
					`;
		EventBus.$emit('app:errMsgOn', message);
	}
	return routingInfo;
};

/**
 * EUC API 호출
 * @param state
 * @param payload
 * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<any, Record<string, any>>|AuthenticatorResponse>}
 * @constructor
 */
export const CALL_EUC_API = async ({ state }, payload) => {
	let res;
	try {
		const routingInfo = getRoutingInfo({ state }, payload);
		let requestPath = routingInfo.path;
		if (payload?.path) {
			if (payload.path.startsWith('/')) {
				requestPath += payload.path;
			} else {
				requestPath += '/' + payload.path;
			}
		}

		// API 호출 시 필요한 정보
		const requestParam = {
			host: routingInfo.host,
			path: requestPath,
			timeout: payload.timeout || routingInfo.timeout,
			method: routingInfo.method,
			data: payload.data,
			responseType: payload.responseType,
			headers: payload.headers,
		};

		EventBus.$emit('app:progress', payload?.loading || false);

		res = await ucApi.callApi(requestParam);

		// 개발일 경우 response console.log 출력
		if (process.env.NODE_ENV !== 'production') {
			console.log('[DEBUG LOG - EUC API Response]', {
				status: res.status,
				resCode: res.data.header.resCode,
				resMsg: res.data.header.resMsg,
				resDt: res.data.header.resDt,
				totalCount: res.data.header.totalCount,
				totalPages: res.data.header.totalPages,
				baseURL: res.config.baseURL,
				data: res.data.data,
			});
		}

		if (payload.loading) {
			EventBus.$emit('app:progress', false);
		}
	} catch (error) {
		if (payload.loading) {
			EventBus.$emit('app:progress', false);
		}
		if (error.response) {
			// 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
			console.log('[ERROR LOG - EUC API Response]', {
				status: error.response.status,
				resCode: error.response.data.header.resCode,
				resMsg: error.response.data.header.resMsg,
				resDt: error.response.data.header.resDt,
				totalCount: error.response.data.header.totalCount,
				totalPages: error.response.data.header.totalPages,
				baseURL: error.response.config.baseURL,
				data: error.response.data.data,
			});
			return error.response;
		} else if (error.request) {
			return error.request;
		} else {
			return error.message;
		}
	}
	return res;
};
