export const initialState = {
	accessToken: null,
	refreshToken: null,
	routingInfos: null,
	menuList: null,
	codeList: null,
	isKeepLogin: 'N',
	loginId: null,
	loginNm: null,
	siteList: null,
	tenantList: null,
	holidayList: null,
	solutionType: null,
	isShowMyLayerLayout: false,
	themeSetting: null,
	isMobile: null,
	systemList: null,
	encryptionType: null,
	authId: null,
	userInfo: null,
	favorite: null,
	memberState: null,
	isAdminUser: null,
	userMainPage: null,
};

export const resetStateKeys = [
	'accessToken', 'refreshToken', 'menuList', 'codeList', 'loginNm', 'siteList',
	'tenantList', 'holidayList', 'solutionType', 'isShowMyLayerLayout', 'themeSetting', 'encryptionType',
	'authId', 'userInfo', 'favorite', 'memberState', 'isAdminUser', 'userMainPage'
]

export const state = { ...initialState};