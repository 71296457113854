export default {
  COMPONENTS: {
    ADD: 'Add',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    COPY: 'Copy',
    DELETE: 'Delete',
    DELETE_SELECTED: 'Delete Selected',
    DETAIL: 'View Details',
    DOWNLOAD: 'Download',
    DUPLICATE_SELECTED: 'Duplicate Selected',
    EDIT: 'Edit',
    FIRST_PAGE: 'First Page',
    LAST_PAGE: 'Last Page',
    MOVE: 'Move',
    NEXT: 'Next',
    NEXT_PAGE: 'Next Page',
    PREV: 'Previous',
    PREV_PAGE: 'Previous Page',
    REFRESH: 'Refresh',
    SAVE: 'Save',
    APPROVAL: 'Approval',
    COMPLETE: 'Complete',
    SEARCH: 'Search',
    SELECT: 'Select',
    SELECT_ALL: 'Select All',
    UNSELECT_ALL: 'Unselect All',
    UPDATE: 'Update',
    UPLOAD: 'Upload',
    TITLE: 'Title',
    NAME: 'Name',
    DATE: 'Date',
    DEPT: 'Department',
    GROUP: 'Group',
    TYPE: 'Type',
    DESCRIPTION: 'Description',
    ALL: 'All',
    USE_STATUS: 'Use Status',
    ORDER: 'Order',
    REGISTRANT: 'Registerer',
    REGISTRATION_DATE: 'Register Date',
    REGISTRATION_DATE_TIME: 'Register DateTime',
    MODIFIER: 'Modifier',
    MODIFY_DATE: 'Modify Date',
    MODIFY_DATE_TIME: 'Modify DateTime',
    TARGET: 'Target',
    NON_TARGET: 'Non-target',
    OTHERS: 'Others',
    GROUP_NAME: 'Group Name',
    INDIVIDUAL: 'Individual',
    CONTAINS: 'Contains',
    BETWEEN: 'Between',
    ENDS_WITH: 'Ends with',
    EQUAL: 'Equals',
    GREATER_THAN: 'Greater than',
    GREATER_THAN_OR_EQUAL: 'Greater than or equal to',
    LESS_THAN: 'Less than',
    LESS_THAN_OR_EQUAL: 'Less than or equal to',
    NOT_CONTAINS: 'Does not contain',
    NOT_EQUAL: 'Does not equal',
    STARTS_WITH: 'Starts with',
    IS_BLANK: 'Is blank',
    IS_NOT_BLANK: 'Is not blank',
    AND: 'And',
    OR: 'Or',
    NOT_AND: 'Not And',
    NOT_OR: 'Not Or',
    EXPAND_LIST: 'Expand List',
    COLLAPSE_LIST: 'Collapse List',
    DOWNLOAD_REASON: 'Download Reason',
    SEARCH_PERIOD: 'Search Period',
    FILE: 'File',
    FILE_SELECT: 'File Select',
    METHOD: 'Method',
    RESULT: 'Result',
    PHONE_NUMBER: 'Phone Number',
    GRADE: 'Grade',
    PERSONAL_INFORMATION: 'Personal Information',
    PERSONAL_MANAGEMENT: 'Personal Management',
    PATH: 'Path',
    USER_STATE: 'User State',
    CATEGORY: 'Category',
    MOVE_TO_TOP: 'Move to the top',
    MOVE_UP: 'Move up',
    MOVE_DOWN: 'Move down',
    MOVE_TO_BOTTOM: 'Move to the bottom',
    SAVE_ORDER: 'Save Order',
    SETTING: 'Setting',
    NOT_SETTING: 'Not Setting',
    ADD_SETTING: 'Add Setting',
    REMOVE_SETTING: 'Remove Setting',
    RESET: 'Reset',
    DROP_FILE: 'or Drop file here',
    PRE_LISTENING: 'Pre listening',
    LISTENING: 'Listening',
    LOGIN: 'Login',
    VIEW_STATUS: 'View Status',
  },
  COMMON: {
    MESSAGE: {
      SYSTEM_ERROR: 'There is a system error. Please contact the administrator.',
      CHECK_DEPT_DEPTH: 'Adding departments is only possible up to 4 levels.',
      CHECK_MENU_DEPTH: 'Adding menus is only possible up to 4 levels.',
      CHANGED_ACTIVE: 'The selected account has been changed to active status.',
      CMN_CFM_DELETE: 'Are you sure you want to delete?',
      CMN_CFM_DELETE_SELECTED: 'Are you sure you want to delete the selected data?',
      CMN_CFM_DUPLICATE_SELECTED: 'Are you sure you want to copy the selected data?',
      CMN_CFM_SAVE: 'Are you sure you want to save?',
      CMN_ERROR: 'An error occurred while processing data.',
      CMN_ERROR_INTERNAL_SERVER: 'Server error.',
      CMN_ERROR_TIMEOUT: 'The set request time ({timeout}) ms has been exceeded.',
      CMN_NO_ADD: 'No more data can be added.',
      CMN_NO_CHANGED: 'No data has been changed.',
      CMN_NO_DATA: 'No data available.',
      CMN_NOT_SELECTED: 'No target selected.',
      CMN_ONE_SELECT: 'Please select only one target.',
      CMN_PREPARING: 'Preparing.',
      CMN_SUC_DELETE: 'Deleted successfully.',
      CMN_SUC_SAVE: 'Saved successfully.',
      CMN_SUC_UPDATE: 'Changed successfully.',
      CMN_SUCCESS: 'Processed successfully.',
      DISALLOWED_FILE: 'This file type is not allowed.',
      EXIST_ACCOUNT: 'An account already exists for the selected target.',
      EXIST_PERSONAL: 'A personnel data target for the selected employment status already exists.',
      EXIST_UNCHANGED_DATA: 'There is data that has not been changed.',
      CANNOT_BE_DEL_ACCOUNT_AUTH: 'Cannot delete as there are granted permissions to the user.',
      OVER_SIZE_FILE: 'The file exceeds the allowed size({size}) and cannot be uploaded.',
      OVER_LICENSE_COUNT:
        'You have exceeded the number of licenses <br/>that can be registered and cannot register any more accounts.',
      PWD_INVALID: 'Invalid password.',
      PWD_NON_EMPTY: 'Please enter the password without spaces.',
      PWD_NON_KR: 'You cannot use Korean in your password.',
      REQUIRED_VALUE: 'This is a required value.',
      REQUIRED_VALUE_IS: '[{value}] is a required value.',
      REQUIRED_FOR_CHILD_CODE: 'You must enter a sub-code value.',
      REQUIRED_IF_CHILD_CODE_PRESENT: 'If you have entered a sub-code value, <br/>you must add a sub-code name data.',
      REQUIRED_DEPT_SEARCH: 'Selection of the department for search exposure is required.',
      REQUIRED_DEPT_ADMIN: 'If the user type is "Department Admin", <br/>selection of the department is required.',
      REQUIRED_FILE_ADDITION: 'Please add a file to upload.',
      REQUIRED_PARENT_CODE: 'You must add parent code name data.',
      REQUIRED_PARENT_DEPT: 'Select the parent department to which you want to add sub-departments.',
      REQUIRED_PARENT_MENU: 'Select the parent menu to add sub-menus.',
      REQUIRED_SELECT_USER: 'Please select a user.',
      REQUIRED_SELECT_DEPT: 'Please select a department first.',
      REQUIRED_DATE: 'Please select a date.',
      SEARCH_DATE_RANGE_ERROR: 'The end date must be greater than or equal to the start date.',
      REQUIRED_DOWNLOAD_REASON: 'Enter the reason for downloading.',
      INVALID_DATE_FORMAT: 'The date format is not valid.',
      PLEASE_SELECT_DATE: 'Please select a date.',
      SEARCH_TIME_MAX_RANGE_ERROR: 'The start time must be less than or equal to the end time.',
      SEARCH_TIME_MIN_RANGE_ERROR: 'The end time must be greater than or equal to the start time.',
      REQUIRED_VALUE_VALIDATION_ERROR: 'Please input required value.',
      PLEASE_SAVE_THE_CHANGES: 'Please save the changes.',
      EMPLOYEE_NUMBER_ALREADY_IN_USE: 'Employee number already in use.',
      ERROR_AUTH_DATA_FETCH: 'Error in fetching permission data.',
      NUMBER_ENTER: 'Please Enter Only Numbers.',
      PLEASE_SELECT_TARGET: 'Please select a target.',
      PLEASE_SELECT_TO: 'Please select a {target}.',
      PLEASE_SELECT_SETTING: '설정을 선택해주세요.',
      CALL_API_FAIL: 'Data retrieval failed. cause : [{message}]',
      ACTIVATE_SELECTED_ACCOUNT: 'Do you want to activate the selected account?',
      PASSWORD_MISMATCH: 'Passwords do not match. Please verify.',
      NOT_DUPLICATE_VALUE_IS: '[{value}] Values cannot be duplicated.',
      FORGOT_PASSWORD: 'If you forgot your password, please contact the administrator.',
      ENTER_ID: 'Please enter the ID.',
      ENTER_PASSWORD: 'Please enter the password.',
      CONFIRM_DELETE_WITH_SUB_PERMISSIONS: 'Deleting will also remove all sub-permissions. <br>Do you want to continue?',
    },
    WORD: {
      ADD_MAIN_SETTING: 'Add main settings',
      SETTING_TARGET: 'Setting target',
      MAIN_PAGE_TYPE: 'Main Page type',
      MAIN_PAGE_ID: 'Main Page ID',
      AUTH: 'Auth',
      LOGIN_ID: 'LoginID',
      SITE: 'Site',
      SITE_NAME: 'Site Name',
      TENANT: 'Tenant',
      TENANT_NAME: 'Tenant Name',
      CONTENT: 'Content',
      LOGIN: 'Login',
      LOGOUT: 'Logout',
      PASSWORD: 'Password',
      SAVE_ID: 'Save ID',
      TYPE: 'Type',
      HOLIDAY_NAME: 'Holiday Name',
      HOLIDAY: 'Holiday',
      LOAD_HOLIDAY: 'Load Holiday',
      COMMON: 'Common',
    },
  },
  UC: {
    MESSAGE: {
      APPROVED: 'Approval has been processed.',
      UNSAVED: 'There is unsaved data.',
      CHECK_PICKUP_DEPT: 'Name Must Start With `PG-D_`.',
      CHECK_PICKUP_ETC: 'Name Must Start With `PG-P_`.',
      CHECK_ADD_COUNT: 'Adding Data is only possible up to 10.',
      CHECK_EXCHANGER_TYPE: 'Only One Exchanger Type Must Be Entered.',
      START_SYNC: 'Do You Want To Synchronize DB Data?',
      CHANGE_LIST_ORDER: 'The HuntList Order Changes As Well.',
      REQUIRED_SELECT_HUNTPILOT: 'Please select a HuntPilot first.',
      REQUIRED_SELECT_HUNTLIST: 'Please select a HuntList first.',
      REQUIRED_SELECT_LINEGROUP: 'Please select a LineGroup first.',
      PILOT_LIST_ONE_SELECT: 'Please select only one target HuntPilot and HuntList.',
      PILOT_LIST_MISS_MATCH: 'This HuntList does not belong to the HuntPilot',
      DESCRIPTION_ENTER: 'Please Enter A Description.',
      NUMBER_ENTER: 'Please Enter Only Numbers.',
      CHANGED_DATA: 'There Is Changed Data.',
      REQUIRED_EXCHANGER_CHECK: 'Please Exchanger Check First.',
      ENCRYPTED_FILE_UPLOAD_NOT_ALLOWED: 'Encrypted Files Cannot Be Uploaded.',
      DN_ENTER: 'Please Enter Dial Number.',
      DEVICE_NAME_ENTER: 'Please Enter Device Name.',
      CHECK_MAC_ADDR: 'MAC Address Cannot Be Duplicated.',
      NO_PERSON_INFO: 'There is no personnel information for the logged in user.',
      REQUIRED_TITLE: 'Please Enter A Title.',
      REQUIRED_APPROVER: 'Please Select An Approver.',
      REQUIRED_REQUEST_ITEMS: 'Please Enter Request Items.',
      PLACEHOLDER_EXISTENCE_CONFIRMATION_QUERY: 'Please Enter Existence Confirmation SQL.',
      PLACEHOLDER_DELETE_QUERY: 'Please Enter Delete SQL.',
      PLACEHOLDER_FILE_DELETE_DEFAULT_PATH: 'Default path when deleting a file (after /home/ecss/api/file, Ex: /report/excel)'
    },
    WORD: {
      DUE_DATE: 'Due Date',
      USER_NO: 'Employee Number',
      REQUESTER: 'Requester',
      APPROVER: 'Approver',
      APPROVAL_DATE: 'Approval Date',
      APPROVAL_FL: 'Approval Status',
      WORKER: 'Worker',
      REQUESTER_INFO: 'Requester Information',
      USER_SELECT: 'Select User',
      APPROVAL_SELECT: 'Select Approver',
      WORKER_SELECT: 'Select Worker',
      APPLY_TARGET: 'Apply Target',
      TYPE_MANAGEMENT: 'Type Management',
      DETAILED_TYPE: 'Detailed Type',
      DETAILED_TYPE_MANAGEMENT: 'Detailed Type Management',
      DETAILED_TYPE_COUNT: 'Number of Detailed Types',
      TYPE: 'Type',
      TYPE_NAME: 'Type Name',
      REQUEST_TYPE: 'Request Type',
      AFTER_LINE: 'After Line',
      DEPT_ATTRIBUTES: 'Department Attributes',
      BLOCKING_POLICY: 'Blocking Policy',
      EXCEPTION_POLICY: 'Exception Policy',
      EXCEPTION_SETTING: 'Exception Setting',
      ALLOW: 'Allow',
      BLOCK: 'Block',
      EXCHANGER: 'Exchanger',
      DEPT_NAME: 'Dept Name',
      DEPT_CODE: 'Dept Code',
      PATTERN: 'Pattern',
      PARTITION: 'Partition',
      DEPT_NAME_SEARCH: 'Department Name Search',
      UNUSED: 'Unused',
      VIEW_DEPT_USE: 'View Only Departments In Use',
      CREATE_DEPT_PICKUP: 'Create PickupGroup By Department',
      CREATE_ETC_PICKUP: 'Create PickupGroup By Separately',
      DEPT_PICKUP_INFO: 'PickupGroup Information By Department',
      CREATE_DEPT: 'Create By Department',
      PICKUP_MANAGE: 'PickupGroup Management',
      REQUESTER_SELECT: 'Requester Select',
      SENDER: 'Sender',
      SENDER_NUMBER: 'Sender Number',
      SENDER_DEPT: 'Sender Department',
      RECEIVER: 'Receiver',
      RECEIVER_NUMBER: 'Receiver Number',
      RECEIVER_DEPT: 'Receiver Department',
      BLOCK_DATE: 'Block Date',
      BLOCK_END_DATE: 'Block End Date',
      BLACK_LIST: 'Black List',
      BLACK_LIST_HISTORY: 'Black List History',
      INDEFINITE: 'Indefinite',
      PROGRESS_STATUS: 'Progress Status',
      DISTRIBUTIONALGORITHM: 'Distribution Algorithm',
      RNAREVERSIONTIMEOUT: 'Rna Reversion Timeout',
      HUNTGROUP_SYNC: 'Huntgroup Sync',
      DEVICE_SYNC: 'Device Sync',
      LINE_SYNC: 'Line Sync',
      LINEGROUPDN_SET: 'LineGroupDn Set',
      PILOT_LIST_SET: 'Pilot/List Add',
      LIST_LINEGROUP_SET: 'List/LineGroup Set',
      LINEGROUP_ADD: 'LineGroup Add',
      CHECK: 'Check',
      HUNTPILOT: 'HuntPilot',
      HUNTLIST: 'HuntList',
      LINEGROUP: 'LineGroup',
      LINEGROUPDN: 'LineGroupDn',
      SAVE_ORDER: 'Save Order',
      TOTAL: 'Total',
      COUNT: '',
      ALL_LIST: 'All List',
      MODEL: 'Model',
      STATUS: 'Status',
      DEVICE_INFO: 'Device Info ',
      DEVICE_INFO_DETAIL: 'Device Info Details',
      LINE_INFO: 'Line Info',
      DEVICE_NAME: 'Device Name',
      DEVICEPOOL: 'DevicePool',
      SOFTKEY: 'SoftKey',
      NUMBER: 'Number',
      LABEL: 'Label',
      SERIAL: 'Serial',
      PHONEBUTTON: 'Button',
      APPLY: 'Apply',
      EXPANSION_MODULE: 'Expansion Module',
      EXPANSION_MODULE_SET: 'Expansion Module Set',
      PICKUPGROUP_SET: 'PickupGroup Set',
      LINE_SET: 'Line Set',
      CSS: 'Css',
      FORWARDNO: 'Forward Number',
      PICKUPGROUP: 'PickupGroup Name',
      REC: 'Rec',
      REC_PERMISSION: 'Rec Permission',
      EXCEL_DOWNLOAD: 'Excel Download',
      EXCEL_UPLOAD: 'Excel Upload',
      EXCHANGER_SELECT: 'Exchanger Select',
      EXCHANGER_CHECK: 'Exchanger Check',
      EXCHANGER_APPLY: 'Exchanger Apply',
      INDEX: 'Index',
      LINE_ADD: 'Line Add',
      TARGET_DEVICE: 'Target Device',
      NEW_DEVICE: 'New Device',
      CONTACT: 'Contact',
      ALERTINGNAME: 'Alerting Name',
      EXTERNALCALLCONTROLPROFILE: 'External Call Control Profile',
      DISPLAY: 'Display',
      DISPLAY_NAME: 'Display Name',
      FIRST_NAME: 'First Name',
      MIDDLE_NAME: 'Middle Name',
      LASG_NAME: 'Last Name',
      CSF: 'PC JABBER',
      BOT: 'Android',
      TCT: 'IPhone',
      TAB: 'Tablet',
      CHECK_EXISTING_DEVICE: 'Delete The Existing Device',
      GROUP_NUMBER: 'Group Number',
      REG_NUMBER: 'Registered Number',
      LICENSE_STATUS: 'License Status',
      LICENSE_HISTORY: 'License History',
      LICENSE: 'License',
      APUSRASSIGNEDMODEL: 'Apusr Assigned Model',
      SMARTASSIGNEDMODEL: 'Smart Assigned Model',
      MODEL_SYNC: 'Model Sync',
      LICENSE_SYNC: 'License Sync',
      SYNC: 'Synchronization',
      CALL_MANAGER_GROUP: 'Call Manager Group',
      REGION: 'Region',
      DATETIME_SETTING: 'Date Time Setting',
      CREATE: 'Create',
      WORK_ID: 'Work ID',
      RECENT_WORK_HISTORY: 'Recent Work History',
      EXCEL_FILE: 'Excel File',
      OTHERS_FILE: 'Others File',
      ASCII_ALERTINGNAME: 'Ascii Alerting Name',
      HUNTINFO: 'Hunt Infomation',
      PILOT_DESCRIPTION: 'Pilot Description',
      LIST_DESCRIPTION: 'List Description',
      AUTOMATION_MANAGEMENT: 'Automation Management',
      AUTOMATION_SETTING: 'Automation Setting',
      AUTOMATION_CODE: 'Automation Code',
      TRANSFORM_NUMBER: 'Transform Number',
      EXISTENCE_CONFIRMATION_QUERY: 'Existence Confirmation Query',
      DELETE_QUERY: 'Delete Query',
      FILE_DELETE_FLAG: 'File Delete Flag',
      STORAGE_PERIOD: 'Storage Period (Month)',
      DATA_MANAGEMENT_HISTORY: 'Data Management History',
      HOT_LINE: 'Hot Line',
      MEMO: 'Memo',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      FORWARD_NUMBER: 'Forward Number',
      RESET: 'Reset',
    },
  },
  EWM: {
    MESSAGE: {
      WORK_SCHEDULE_CANNOT_MODIFY_PAST_DATES: 'Past dates cannot be modified for work changes.',
      SELECT_COUNSELOR_TO_MODIFY: 'Please select a counselor to modify.',
      CONFIRM_REVOKE_ORDER: 'Do you want to cancel the order?',
      REQUIRED_AGENT_ID: 'Please enter the agent ID.',
      REQUIRED_AGENT_NM: 'Please enter the agent name.',
      REQUIRED_AGENT_DN: 'Please enter the extension number.',
      REQUIRED_DEPT: 'Please select a department.',
      REQUIRED_JIKGUP: 'Please select a rank.',
      REQUIRED_JIKWEE: 'Please select a position.',
      REQUIRED_AUTH_ID: 'Please select account permissions.',
      REQUIRED_WORKGROUP: 'Please select a workgroup.',
      REQUIRED_USED_CTI_FL: 'Please select the CTI use status.',
      REQUIRED_LOGIN_FL: 'Please select the login integration status.',
    },
    WORD: {
      ATTENDANCE_STATUS_NORMAL: 'Normal',
      ATTENDANCE_STATUS_LATE: 'Late',
      ATTENDANCE_STATUS_EARLY_LEAVE: 'Early Leave',
      ATTENDANCE_STATUS_ABSENCE: 'Absent',
    },
  },
  LLM: {
    MESSAGE: {
      WARNING_DELETE_PROJECT: 'When deleting a project, all data is deleted together.<br>Do you want to delete?',
      DUPLICATE_PROMPT_NAME: 'This is the prompt title already in use.',
      UNSAVED_PROMPT_CHANGE_ALERT: 'Your prompt work has not been saved. <br>Do you want to change it?',
      UNSAVED_PROMPT_MOVE_ALERT: 'Your prompt work has not been saved. <br>Do you want to move?',
      SAVE_SYSTEM_PROMPT: 'Do you want to save system prompt?',
      SAVE_EVALUATION_PROMPT: 'Do you want to save evaluation prompt?',
      PLEASE_ADD_PROMPT: 'There are no registered prompts. Please add a new prompt\n.',
      NEW_PROMPT_ADD_ALERT: 'A new prompt is created without modifying the selected prompt.',
      NEW_PROMPT_NAME_ALERT: 'Enter a title for the new prompt.',
    },
    WORD: {
      PROJECT: 'Project',
      PROJECT_ID: 'Project ID',
      WORK_CONFIG: 'Work Config',
      WORK: 'Work',
      RUNNING_TEST: 'Running Test',
      GO_TO_LIST: 'GoTo List',
      TEST_WORK: 'Test Work',
      DETAIL: 'Detail',
      PROMPT: 'Prompt',
      SYSTEM_PROMPT: 'System Prompt',
      EVALUATION_PROMPT: 'Evaluation Prompt',
      PROMPT_ADD: '+ Add New Prompt',
      SYSTEM_PROMPT_ADD: 'Add New System Prompt',
      EVALUATION_PROMPT_ADD: 'Add New Evaluation Prompt',
      COPY_ADD: 'Copy Add',
      SELECT_PROMPT: 'Select Prompt',
      CONTENT: 'Content',
    },
  },
  CC: {
    MESSAGE: {
      INPUT_GROUP_NAME: 'Please enter the Group Name.',
      REQUIRED_GROUP_NAME: 'Group name is required.',
      SELECT_GROUP: 'Please select a group.',
      DUPLICATE_NAME: 'This name is already registered.',
      CFM_UPDATE_INFO: 'Would you like to edit the information?',
      CFM_DELETE_SELECTED_WITH_CHILD: `Are you sure you want to delete the selected data?<br/>Child data will also be deleted.`,
      RESET_IBG: 'Reset IBG Settings',
      RESET_MAIN_NUMBER: 'Reset Main Number Settings',
      NO_DELETE_DNIS_NOTICE: 'There is a notice being posted in the DNIS, so it cannot be deleted.',
      NO_DELETE_DNIS_SCHEDULE: 'There is a operating schedule being posted in the DNIS, so it cannot be deleted.',
      START_DATE_OUT_OF_RANGE: 'Start date must be less than or equal to end date.',
      END_DATE_OUT_OF_RANGE: 'The end date must be greater than or equal to the start date.',
      START_TIME_OUT_OF_RANGE: 'Start time must be less than or equal to end time.',
      END_TIME_OUT_OF_RANGE: 'The end time must be greater than or equal to the start time.\n',
      NO_DISABLED_NOT_USED_DNIS_NOTICE: 'Notice set in the representative number cannot be changed to unused state.',
      NO_DISPLAY_USED_DNIS_NOTICE: `The main number for which other announcements have already been established<br>is You are not exposed from the list.`
    },
    WORD: {
      GROUP_INFO: 'Group Info',
      TH_GROUP_NAME: 'th Group Name',
      GROUP_NAME: 'Group Name',
      CHILD_GROUP: 'Child Group',
      CHILD: 'Child',
      REGISTER_1ST_GROUP: 'Register for a first group',
      REGISTER_2RD_GROUP: 'Register for a secondary group',
      ADD_IBG: 'Add IBG',
      ADD_TEAM: 'Add Team',
      ADD_SKL: 'Add Skill Group',
      IBG: 'IBG',
      IBG_NAME: 'IBG Name',
      TEAM: 'Team',
      SKL: 'Skill Group',
      ERS_NAME: 'ERS Name',
      CTI_NAME: 'CTI Name',
      VIEW_DNIS_USE: 'View only DNIS in use',
      OPERATION_SETTINGS: 'Operation Settings',
      IBG_SETTINGS: 'Ibg Settings',
      CONFIG_SETTINGS: 'Config Settings',
      MAIN_NUMBER: 'Main Number',
      MAIN_NUMBER_SETTING: 'Main Number Setting',
      DNIS_TITLE: 'DNI Title',
      SVC_TP_TITLE: 'Service Template',
      OP_SCHEDULE: 'Operation Schedule',
      SERVICECODE: 'Service Code',
      NOTICE: 'Notice',
      DISPLAY_PERIOD: 'Display Period',
      END_TYPE: 'End Type',
      CONFIG_SETTING: 'Config Setting',
      KEY: 'Key',
      VALUE: 'Value',
      DESCRIPTION: 'Description',
      SCHEDULE_NAME: 'Schedule Name',
      BASIC_INFO: 'Basic Info',
      BASIC_OP_TIME_SETTING: 'Basic Operation Time Setting',
      OP_SCHEDULE_NAME: 'Operation Schedule Name',
      OP_DAY_OF_WEEK: 'Operation Day of the Week',
      OP_TIME: 'Operation Time',
      BREAK_TIME_APPLY_TYPE: 'Break Time Apply Type',
      BREAK_TIME: 'Break Time',
      TWENTY_FOUR_HOUR: 'Twenty-four hour',
      WORK_FLAG: 'Work Flag',
    },
  },
};
