<!--
  PACKAGE_NAME : src\components\report\search-box.vue
  FILE_NAME : search-box.vue
  AUTHOR : kwmoon
  DATE : 2024-06-19
  DESCRIPTION : 위자드 보고서 상단 검색 박스
  TODO: 코드 정리 및 영역별 컴포넌트 분리 예정
-->
<template>
  <div class="container">
    <!--   검색 컴포넌트 컨테이너(모달 제외)  -->
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <!-- START: 검색조건 관리 -->
      <popoverSearchCondition
        ref="popoverSearchCondition"
        :info="reportInfo"
        :getReportSearchParams="getReportSearchParams"
        :onClickSearchCondition="handleSetSearchCondition"
      />

      <!-- START: 기간 / 대상 / 상세 / 보고서 유형 -->
      <div class="report-search">
        <div class="page_search_box">
          <div class="inner2 clearfix">
            <div class="flex justify-between">
              <div>
                <!-- 기간설정 -->
                <button
                  ref="dateLayerBtn"
                  :class="styles.offLayerBtn"
                  style="height: 29px"
                  @click="toggleHiddenSection($event, 'dateHiddenLayer')"
                >
                  기간 <span :class="styles.offUpDown"></span>
                </button>
                <div
                  class="ui-datepicker-item"
                  style="margin-right: 5px"
                  @click="toggleHiddenSection($event, 'dateHiddenLayer', 'dateLayerBtn')"
                >
                  <input type="text" class="pointer calr dx-outline" :value="setPeriodDate" style="width: 192px" readonly />
                </div>
                <span class="mr-10 pointer" @click="toggleHiddenSection($event, 'dateHiddenLayer', 'dateLayerBtn')">
                  <input type="text" class="pointer dx-outline" :value="status.periodResult" style="width: 255px" readonly />
                </span>
                <!-- 대상설정 -->
                <span class="mar-ri10">
                  <button
                    v-for="v in PAGE_DATA.targetBtns"
                    :class="styles.offLayerBtn"
                    style="height: 29px"
                    :ref="`${v.type}LayerBtn`"
                    :key="v.type"
                    :data-type="v.type"
                    @click="toggleHiddenSection($event, `${v.type}HiddenLayer`)"
                  >
                    {{ v.name }} <span :class="styles.offUpDown"></span>
                  </button>
                </span>
                <span>
                  <input id="selectedSizeMessage" type="text" class="w150 dx-outline mr-0" :value="computedSelectedSizeMsg" readonly />
                </span>
                <!-- 상세 -->
                <span v-if="PAGE_DATA.detailBtnInfo">
                  <span class="ml-20 mr-15">상세</span>
                  <span class="mar-ri10">
                    <button
                      :class="styles.offLayerBtn"
                      style="height: 29px"
                      ref="detailLayerBtn"
                      data-type="detail"
                      @click="toggleHiddenSection($event, `detailHiddenLayer`)"
                    >
                      조건 <span :class="styles.offUpDown"></span>
                    </button>
                  </span>
                </span>
                <!-- 보고서유형 -->
                <span v-show="init.reportTypeOpts.length > 0">
                  <span class="ml-20 mr-15">유형</span>
                  <span class="top2">
                    <DxSelectBox
                      placeholder="보고서 유형"
                      :data-source="init.reportTypeOpts"
                      display-expr="name"
                      value-expr="value"
                      v-model="formData.searchParams.report_type"
                      :styling-mode="PAGE_DATA.dxStyled"
                      @value-changed="onChangeReportType"
                      width="100px"
                      :height="30"
                    >
                    </DxSelectBox>
                  </span>
                </span>
              </div>
              <div v-if="isWizardReport">
                <button type="button" class="btn_M box-btn-search" style="min-height: 29px; height: 29px" @click="onSearchReport(0)">
                  조회
                </button>
                <button v-if="!status.popupmode" type="button" class="btn_XXS white" style="height: 29px" @click="onSearchReport(0, true)">
                  <img src="@/assets/images/report/popup_icon.png" /> 새창 조회
                </button>
                <button type="button" id="popoverSearchConditionBtn" class="btn_XXS white px-0.5" style="height: 29px; padding: 0">
                  <img src="@/assets/images/report/search_manage_icon.png" />
                </button>
                <button
                  v-show="$store.getters.getLoginId === 'ecstel' || $_isPermitted('auth_edit_xml')"
                  type="button"
                  class="btn_XXS white"
                  style="height: 29px; padding: 0"
                  @click="toggleXmlModal"
                >
                  <img src="@/assets/images/report/edit_xml_icon.png" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 캘린더 클릭시 상세 화면 레이어 출력 -->
        <div ref="dateHiddenLayer" class="border-t border-gray-200 pt-6 pb-3 hidden">
          <div class="flex flex-wrap justify-between py-2 space-y-6 md:space-y-4 lg:space-y-0">
            <!-- 기간선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">기간 선택</h2>
              <div class="space-y-4 md:space-y-2 lg:space-y-0 lg:flex lg:items-center lg:space-x-4 mb-4">
                <div ref="periodSection" class="flex space-x-1">
                  <button
                    v-for="({ name, type }, index) in init.periodBtns"
                    class="btn_XXS blue2"
                    :key="index"
                    :data-type="type"
                    @click="onClickPeriodBtn(type)"
                  >
                    {{ name }}
                  </button>
                </div>
                <div class="space-y-2 lg:space-y-0 lg:flex lg:items-center lg:space-x-2 w-full lg:w-auto">
                  <!-- 년, 월 -->
                  <div v-show="['MONTH', 'YEAR'].includes(status.periodType)" class="flex flex-wrap space-x-1.5">
                    <DxSelectBox
                      v-model="init.currentYear"
                      :data-source="init.periodYear"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="PAGE_DATA.dxStyled"
                      @value-changed="onChangePeriod($event, 'YEAR')"
                      width="123px"
                      :height="30"
                    ></DxSelectBox>
                    <DxSelectBox
                      v-show="['MONTH'].includes(status.periodType)"
                      v-model="init.currentMonth"
                      :data-source="init.periodMonth"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="PAGE_DATA.dxStyled"
                      @value-changed="onChangePeriod($event, 'MONTH')"
                      width="100px"
                      :height="30"
                    ></DxSelectBox>
                  </div>
                  <!-- 일, 기간선택 -->
                  <div v-show="['DAY', 'RANGE'].includes(status.periodType)" class="flex flex-wrap items-center space-x-2">
                    <!-- 일 -->
                    <div class="ui-datepicker period flex items-center space-x-1.5">
                      <DxDateBox
                        ref="startDt"
                        v-model="status.startDt"
                        :styling-mode="PAGE_DATA.dxStyled"
                        :openOnFieldClick="true"
                        width="123px"
                        @value-changed="onChangePeriod($event, 'dayStart')"
                        type="date"
                        display-format="yyyy-MM-dd"
                        dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                      ></DxDateBox>
                      <!-- 기간선택 -->
                      <div v-show="['RANGE'].includes(status.periodType)" class="flex items-center space-x-1.5">
                        <span>~</span>
                        <DxDateBox
                          ref="endDt"
                          v-model="status.endDt"
                          :styling-mode="PAGE_DATA.dxStyled"
                          width="123px"
                          :openOnFieldClick="true"
                          @value-changed="onChangePeriod($event, 'dayEnd')"
                          type="date"
                          display-format="yyyy-MM-dd"
                          dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                        ></DxDateBox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 요일선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">요일 선택</h2>
              <div class="flex flex-wrap space-x-4 mb-6">
                <span v-for="item in PAGE_DATA.daysChks" :key="item.value" class="flex items-center pt-1.5 space-x-1.5">
                  <DxCheckBox
                    :key="item.id"
                    :text="item.alt"
                    :value="item.value"
                    v-model="item.checked"
                    :disabled="status.disabledDays"
                    @value-changed="onClickPeriodOptions"
                  />
                </span>
              </div>
            </div>
            <!-- 시간선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">시간 선택</h2>
              <div class="flex flex-wrap items-center space-x-1.5 mb-4">
                <DxSelectBox
                  placeholder="시"
                  :data-source="init.times"
                  display-expr="name"
                  v-model="formData.searchParams.startTime_H"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
                <span>:</span>
                <DxSelectBox
                  placeholder="분"
                  :data-source="init.minutes"
                  display-expr="name"
                  v-model="formData.searchParams.startTime_M"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
                <span>~</span>
                <DxSelectBox
                  placeholder="시"
                  :data-source="init.times"
                  display-expr="name"
                  v-model="formData.searchParams.endTime_H"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
                <span>:</span>
                <DxSelectBox
                  placeholder="분"
                  :data-source="init.minutes"
                  display-expr="name"
                  v-model="formData.searchParams.endTime_M"
                  value-expr="value"
                  :styling-mode="PAGE_DATA.dxStyled"
                  @value-changed="onClickPeriodOptions"
                  width="82px"
                  :disabled="status.disabledTimes"
                  :height="30"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- END: 기간 / 대상 / 보고서 유형 -->
        <!-- START: 대상[CHECK] 레이어 -->
        <div ref="checkHiddenLayer" class="border-top-1px layer-box hidden">
          <div
            v-if="computedCheckBtnOptions && computedCheckBtnOptions.length > 1"
            class="border-t border-gray-400 p-3 flex justify-between"
          >
            <div class="flex items-center space-x-2 w-1/2">
              <!-- 조회기준 텍스트와 SelectBox -->
              <span class="text-base font-medium mx-2">조회기준</span>
              <template v-if="PAGE_DATA.targetBtns[0].isIPCC">
                <button
                  class="mdi mdi-help-circle"
                  id="categoryTooltip"
                  @mouseenter="setTooltips('search')"
                  @mouseleave="setTooltips('search')"
                ></button>
                <DxSelectBox
                  class="mr-10"
                  :items="selectShowTreeList"
                  placeholder="선택"
                  display-expr="name"
                  value-expr="index"
                  styling-mode="outlined"
                  :width="200"
                  :value="1"
                  @setDisabled="setDisabled"
                  @selection-changed="onChangeTreeBoxByCategory"
                />
              </template>
              <!-- 조회그룹 (RadioGroup) -->
              <span v-show="computedIsDefaultCti">
                <DxRadioGroup
                  class="check-type col"
                  ref="targetGroupRadio"
                  :dataSource="computedCheckBtnOptions"
                  :value="1"
                  display-expr="name"
                  value-expr="depth"
                  layout="horizontal"
                  @value-changed="changeRadioInGroupSection"
                />
              </span>
            </div>

            <!-- 옵션과 체크박스 그룹 -->
            <div v-show="PAGE_DATA.optionItems.length > 0" class="flex items-center space-x-2 w-1/2">
              <span class="text-base font-medium mr-2">옵션</span>
              <DxCheckBox
                v-for="(item, i) in PAGE_DATA.optionItems"
                :key="i"
                :text="item.name"
                :value="item.checked"
                @value-changed="updateCheckOptionItem($event, i)"
              />
            </div>
          </div>
          <!-- 조회그룹 트리리스트 박스 -->
          <div v-if="computedCheckBtnOptions" class="border-t border-gray-200 h-[450px] flex flex-wrap justify-start gap-2">
            <template v-if="computedIsDefaultCti">
              <DepthTreeBox
                class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
                v-for="(v, i) in computedCheckBtnOptions"
                ref="depthTreeBox"
                :title="v.name"
                :key="v.name"
                :is="DepthTreeBox"
                :dataName="`dept${i + 1}`"
                :nextDataName="v.hasNext ? `dept${i + 2}` : undefined"
                :treeDatas="target[(i === 0 ? '' : 'v_') + `dept${i + 1}`]"
                :disabled="target[`disabled${i + 1}`]"
                :firstBoxHiding="firstBoxHiding"
                :pageLoaded="pageLoaded"
                @clearChekcedItems="clearChekcedItems(i + 1)"
                @setDisabled="setDisabled"
                @setCheckedItems="setCheckedItems"
              />
            </template>
            <template v-if="!computedIsDefaultCti">
              <CategoryTreeBox
                ref="CategoryTreeBox"
                :is="CategoryTreeBox"
                :disabled="false"
                dataName="dept1"
                :key="1"
                :categoryData="this.categoryDatas"
                @setCheckedItems="setCheckedItems"
                :selectedCategory="selectedCategory"
              />
            </template>
          </div>
        </div>
        <!-- END: 대상[DEPTH CHECK] 레이어 -->

        <!-- START: 대상[INPUT] 레이어 -->
        <div ref="inputHiddenLayer" class="page_search_box line_top_1px layer-box hidden">
          <div class="p-20" v-if="PAGE_DATA.inputBtnInfo">
            <div class="flex">
              <div class="" v-for="(option, i) in PAGE_DATA.inputBtnInfo.options" :key="i">
                <label class="mr-10">{{ option.name }}</label>
                <DxTextBox
                  class="mr-20"
                  v-model="target['v_item' + (i + 1)]"
                  :show-clear-button="true"
                  :styling-mode="PAGE_DATA.dxStyled"
                  :width="300"
                >
                </DxTextBox>
              </div>
              <button type="button" class="btn_XS blue2" @click="onClickAddCustomItems">대상 추가</button>
            </div>
            <div class="mt-20" style="width: 1240px; max-height: 200px">
              <DxDataGrid ref="inputTargetGrid" :height="180" :data-source="target.customList" no-data-text="데이터가 존재하지 않습니다.">
                <DxScrolling mode="standard" />
                <DxEditing mode="cell" :allow-updating="true" :use-icons="true" />
                <DxColumn cell-template="indexTemplate" alignment="center" :allow-editing="false" :visible="true" :width="80" />
                <DxColumn
                  v-for="(item, i) in PAGE_DATA.inputBtnInfo.options"
                  :key="i"
                  :caption="item.name"
                  :dataField="'item' + (i + 1)"
                  alignment="center"
                />
                <DxColumn
                  caption="삭제"
                  cell-template="removeTemplate"
                  alignment="center"
                  :allow-editing="false"
                  :visible="true"
                  :width="80"
                />
                <template #indexTemplate="{ data }">
                  <div>
                    {{ data.rowIndex + 1 }}
                  </div>
                </template>
                <template #removeTemplate="{ data }">
                  <div>
                    <DxButton
                      text="삭제"
                      template="<span class='mdi mdi-trash-can'></span>"
                      @click="onDeleteInputTargetItem(data.rowIndex)"
                    />
                  </div>
                </template>
              </DxDataGrid>
            </div>
          </div>
        </div>
        <!-- END: 대상[INPUT] 레이어 -->

        <!-- START: 상세[DETAIL] 레이어 -->
        <div v-if="PAGE_DATA.detailBtnInfo" ref="detailHiddenLayer" class="page_search_box line_top_1px layer-box hidden">
          <div class="clearfix ui-glid-box per33 ui-33">
            <!-- 상세 타겟 선택 -->
            <div class="fl">
              <div class="inner3 clearfix">
                <div class="inner-search pl-10">
                  <div style="height: 500px">
                    <DetailObjectBox
                      :ref="DetailObjectBox"
                      :is="DetailObjectBox"
                      :title="PAGE_DATA.detailBtnInfo.name"
                      :treeDatas="target.detailList"
                      @setCheckedItems="setCheckedItems"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 시간 선택 -->
            <div class="fl">
              <div class="inner3 clearfix">
                <table class="table_form line-bin">
                  <colgroup>
                    <col style="width: 130px" />
                    <col style="width: auto" />
                  </colgroup>
                  <thead class="sub_title_txt">
                    <tr>
                      <td colspan="2">
                        <h2 class="sub-title-bin">시간 선택</h2>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <DxNumberBox
                          :width="200"
                          class="mar_ri10"
                          placeholder="통화시간"
                          v-model="target.talkTime"
                          :show-clear-button="true"
                          :styling-mode="PAGE_DATA.dxStyled"
                        />
                        초
                        <DxSelectBox
                          :height="30"
                          :styling-mode="PAGE_DATA.dxStyled"
                          :data-source="PAGE_DATA.detail.timeOpts"
                          v-model="target.talkTimeOpt"
                          width="95px"
                          class="ml-10"
                          value-expr="value"
                          display-expr="name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <DxNumberBox
                          :width="200"
                          class="mar_ri10"
                          placeholder="대기시간"
                          v-model="target.availTime"
                          :show-clear-button="true"
                          :styling-mode="PAGE_DATA.dxStyled"
                        />
                        초
                        <DxSelectBox
                          :height="30"
                          :styling-mode="PAGE_DATA.dxStyled"
                          :data-source="PAGE_DATA.detail.timeOpts"
                          v-model="target.availTimeOpt"
                          width="95px"
                          class="ml-10"
                          value-expr="value"
                          display-expr="name"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- 전화번호 -->
            <div class="fl pad_le20">
              <div class="inner3 clearfix">
                <table class="table_form line-bin">
                  <colgroup>
                    <col style="width: auto" />
                  </colgroup>

                  <thead class="sub_title_txt">
                    <tr>
                      <td>
                        <h2 class="sub-title-bin">전화번호</h2>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <DxTextBox
                          v-model="target.aniNumber"
                          class="mar_ri10"
                          placeholder="전화번호 입력"
                          :width="400"
                          :show-clear-button="true"
                          :styling-mode="PAGE_DATA.dxStyled"
                        >
                        </DxTextBox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: 상세[DETAIL] 레이어 -->
      </div>
    </div>

    <!-- POPUP(ON, OFF) -->
    <div id="app1">
      <WindowPopup v-model="status.windowPopup" :urls="this.newPopupUrl" :datas="formData.searchParams" :params="newWindow.params" />
    </div>

    <!-- START: XML 히스토리 및 수정 팝업  -->
    <ESPModalPopup
      :isOpen="modal.isOpenXmlModal"
      :option="modal.xmlModalOption"
      @saveModal="saveXmlModal"
      @closeModal="toggleXmlModal(false)"
    >
      <template #content>
        <XmlEditor
          ref="xmlEditor"
          :options="modal.editorOption"
          :history="modal.xmlHistories"
          :reportId="reportInfo ? reportInfo.reportId : 0"
        />
      </template>
    </ESPModalPopup>
    <!-- END: XML 히스토리 및 수정 팝업  -->

    <DxPopover
      target="#categoryTooltip"
      :visible="this.tooltip.isVisible.search"
      :close-on-outside-click="this.tooltip.closeOnOutsideClick"
    >
      <span v-html="this.tooltip.contents"></span>
    </DxPopover>
  </div>
</template>

<script>
  //Dev-Extreme
  import { DxButton } from 'devextreme-vue/button';
  import { DxTagBox } from 'devextreme-vue/tag-box';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxDataGrid, DxColumn, DxScrolling, DxPaging, DxSelection, DxEditing } from 'devextreme-vue/data-grid';
  // 조회그룹 컴포넌트
  import DepthTreeBox from './depth-tree-box.vue';
  import CategoryTreeBox from './category-tree-box.vue';
  import DetailObjectBox from './detail-object-box.vue';
  import ModalUserSearch from './modal-user-search.vue';
  // 제작 컴포넌트
  import DxPopup from '../devextreme/esp-dx-modal-popup.vue';
  import WindowPopup from './WindowPopup.vue';
  import ESPModalPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import { ReportManager } from '@/pages/report/report';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  //Util
  import moment from 'moment';
  import { cloneObj, getResData, isEmpty, isSuccess, isTrue } from '@/plugins/common-lib';
  import { DxNumericRule } from 'devextreme-vue/form';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import PopoverSearchCondition from '@/components/report/searchbox/condition/popover-search-condition.vue';

  //Data
  let vm = null;
  const $ = window.$;
  const DEPTH_NUMBERS = [1, 2, 3, 4, 5];
  export default {
    components: {
      PopoverSearchCondition,
      DxRadioGroup,
      DxNumberBox,
      DxNumericRule,
      DxCheckBox,
      DxButton,
      DxDateBox,
      DxSelectBox,
      DepthTreeBox,
      WindowPopup,
      DxTextBox,
      DxDropDownBox,
      DxDataGrid,
      DxColumn,
      DxSelection,
      DxEditing,
      DxPaging,
      DxScrolling,
      DxPopup,
      DxTagBox,
      DxPopover,
      ModalUserSearch,
      ESPModalPopup,
      XmlEditor,
      DetailObjectBox,
      CategoryTreeBox,
    },
    watch: {
      pageLoaded() {
        if (this.pageLoaded === false) return;
        this.setPageLayout();
        this.initMounted();
      },
    },
    props: {
      /** 보고서 정보 */
      reportInfo: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      newPopupUrl: String,
      pageLoaded: Boolean,
      getChkDepthTarget: Function,
      getSelectedReportFilterKeys: {
        type: Function,
        default: () => ['1', '2', '4'],
      },
      isWizardReport: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        DepthTreeBox: 'DepthTreeBox',
        CategoryTreeBox: 'CategoryTreeBox',
        DetailObjectBox: 'DetailObjectBox',
        PAGE_DATA: {
          /***** components setting start ****/
          checkBtnInfo: null,
          inputBtnInfo: null,
          detailBtnInfo: null,
          optionItems: [],
          //OPTIONS
          useStorageData: true, //로컬스토리지에 저장되어 있는 조회그룹 사용할지 여부
          showReportType: true, //보고서 타입 보이기 여부
          multiDownload: [], // 보고서 일괄 다운로드 (자신 ID도 적어야 함)
          /** MasterQuery
           * {
           *     name: sql name 속성 값
           *     type: "master-query"
           *     objKey: 파라미터 objKey 값
           * }
           */
          lastDepthApi: undefined,
          detail: {
            timeOpts: ReportManager.getDetailTimeOpts(),
          },
          targetBtns: ReportManager.getTargetBtns(),
          daysChks: ReportManager.getDayOfTheWeek(),
          /***** components setting end by report.js ****/

          //STYLE
          dxStyled: 'outlined',
          //ERROR_MESSAGE
          ERR_MSG_DATE: '선택한 날짜를 확인해주시기 바랍니다.',
          ERR_MSG_DAYS: '선택한 요일을 확인해주시기 바랍니다.',
          ERR_MSG_TIME: '선택한 시간을 확인해주시기 바랍니다.',
          ERR_MSG_TARGET: '대상을 하나 이상 선택해주시기 바랍니다.',
          ERR_MSG_CUSTOM_TARGET: '대상을 추가해주시기 바랍니다.',
          ERR_MSG_MAX_SELECTED_TARGET: '대상은 최대 {n}개까지 선택 가능합니다.',
        },
        styles: {
          onLayerBtn: 'btn_XXS blue2 on',
          onUpDown: 'mdi mdi-chevron-up icon-white',
          offLayerBtn: 'btn_XXS white',
          offUpDown: 'mdi mdi-chevron-down',
        },
        //IPCC
        selectShowTreeList: [
          {
            index: 1,
            name: 'CTI 기준',
            value: 'default_cti',
          },
          {
            index: 2,
            name: '인입그룹 카테고리 기준',
            value: 'ipcc_ibg',
          },
          {
            index: 3,
            name: '팀그룹 카테고리 기준',
            value: 'ipcc_agtteam',
          },
        ],
        categoryDatas: [],
        selectedCategory: 'default_cti',
        firstBoxHiding: false,
        //Modal & Tooltip
        modal: {
          isOpenXmlModal: false,
          xmlModalOption: {
            title: 'XML 관리',
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'REPORT',
            reportNm: '',
            useRight: true,
            rsWidth: '30%', // 우측 섹션 넓이
          },
          xmlHistories: [],
        },
        tooltip: {
          isVisible: {
            search: false,
          },
          closeOnOutsideClick: false,
          contents: '',
        },
        newWindow: { params: {} },
        // Param Datas
        ...ReportManager.setInitData(), // init, status, target, formData
      };
    },
    computed: {
      /** 기간 input 박스 날짜 출력 */
      setPeriodDate() {
        const { dayStart, dayEnd } = this.formData.searchParams;
        return `${moment(dayStart).format('YYYY/MM/DD')} ~ ${moment(dayEnd).format('YYYY/MM/DD')}`;
      },
      /**
       * 대상 input 박스 선택한 조회 그룹 개수 출력
       * @returns {string|string}
       */
      computedSelectedSizeMsg() {
        const depth = this.status.depth;
        const selectedBtnType = this.status.selectedBtnType;
        const countMessage = [];
        let allCount = 0;
        try {
          if ('input' === selectedBtnType) {
            allCount = this.target.customList.length;
            countMessage.push(`단일: ${allCount.toLocaleString('ko-KR')} 개`);
          } else {
            // selectedType => check
            const name = this.PAGE_DATA.checkBtnInfo.name;
            let selectedTargetSize = this.getSelectedTarget(depth).length;
            if (this.selectedCategory === 'default_cti') {
              selectedTargetSize = this.getSelectedTarget(depth).length;
            } else if (this.computedIsIpccCategory) {
              selectedTargetSize = this.getSelectedTarget(1).filter(v => v !== null).length;
            }
            countMessage.push(`${name}: ${selectedTargetSize.toLocaleString('ko-KR')} 개`);
            allCount = selectedTargetSize;
          }
        } catch (e) {
          // console.log('selectedSizeMessage:', e);
        }

        let resultMessage = '선택 없음';
        if (allCount > 0) resultMessage = countMessage.join(' | ');
        this.changeFontSizeOfMessage(resultMessage);
        return resultMessage;
      },
      computedCheckBtnOptions() {
        return this.PAGE_DATA?.checkBtnInfo?.options;
      },
      computedIsDefaultCti() {
        return this.selectedCategory === 'default_cti';
      },
      computedIsIpccCategory() {
        return ['ipcc_ibg', 'ipcc_agtteam'].includes(this.selectedCategory);
      },
    },
    methods: {
      /**
       * 대상(input) 삭제 기능 - 단일(customList)
       * @param rowIndex
       */
      onDeleteInputTargetItem(rowIndex) {
        this.target.customList.splice(rowIndex, 1);
      },
      /**
       * 선택 대상 출력 메시지가 길면 글씨를 작게 표현
       * @param message: string
       */
      changeFontSizeOfMessage(message) {
        const fontSizeCls = 'fs-12';
        const countElem = document.getElementById('selectedSizeMessage');
        if (countElem) {
          const messageSize = message.split('').length;
          if (messageSize > 10 && !countElem.classList.contains(fontSizeCls)) {
            countElem.classList.add(fontSizeCls);
          } else if (messageSize < 11) {
            countElem.classList.remove(fontSizeCls);
          }
        }
      },
      /** @description: 툴팁 이벤트 */
      setTooltips(key) {
        this.tooltip.isVisible[key] = !this.tooltip.isVisible[key];
      },
      onChangeTreeBoxByCategory(data) {
        this.selectedCategory = data.selectedItem.value;
      },
      /**
       * 화면 최초 셋팅
       * 기간 > 기간선택(월) > 기간선택(년)
       */
      setPriodMonthAndYear() {
        const months = new Array(12).fill().map((v, i) => {
          const month = i + 1;
          const value = month < 10 ? `0${month}` : month.toString();
          return { name: `${month}월`, value };
        });

        const years = [];
        const ersInitYear = this.$_getReportStartDt.substring(0, 4);
        let year = moment().format('YYYY');
        while (ersInitYear <= year) {
          years.push({ name: year + '년', value: year.toString() });
          year--;
        }
        this.init.periodMonth = months;
        this.init.periodYear = years;
      },
      /**
       * 화면 최초 셋팅
       * 기간 > 시간선택
       */
      setTimeList() {
        const times = new Array(25).fill().map((v, i) => {
          const time = i < 10 ? `0${i}` : i.toString();
          return { name: time, value: time };
        });

        const minutes = ['00', '15', '30', '45'].map(v => ({
          name: v,
          value: v,
        }));
        this.init.times = times;
        this.init.minutes = minutes;
      },
      /**
       * @title: 조회대상 리스트 조회
       * @filePath: data/solution/subPath/common/report-target.xml
       * @description: 위 파일 내에 name === objType 로 반환하는 element의 쿼리를 실행하여 조회대상 리스트 반환
       */
      async fetchReportTargetData(data, useLoading = true) {
        return this.CALL_REPORT_API({
          actionname: 'REPORT_TARGET_RESULT_LIST',
          data: { data },
          loading: useLoading,
        });
      },
      /**
       * @title: [대상] 조회대상 리스트 조회
       * @description
       * 보고서 XML 파일 내 "search - objType" 과 <br>
       * 일치하는 name을 조회하여 <br>
       * dept 1 ~ 5 까지 나누어 저장 (depth 별)
       * */
      async setDeptList() {
        const res = await this.getReportTargetList();
        if (isSuccess(res)) {
          const objectList = getResData(res);
          const temp = { dept1: [], dept2: [], dept3: [], dept4: [], dept5: [] };
          objectList.forEach(v => {
            // this.target[`dept${v.DEPTH}`].push(v);
            temp[`dept${v.DEPTH}`].push(v);
          });

          Object.keys(temp).forEach(v => {
            this.target[v] = cloneObj(temp[v]);
          });
        }
      },
      async setDetailList() {
        if (!this.PAGE_DATA.detailBtnInfo) return;
        const res = await this.getReportTargetList();
        if (isSuccess(res)) {
          this.target.detailList = getResData(res);
        }
      },
      /**
       * @title: 조회대상 리스트 조회
       * @filePath: data/solution/subPath/common/report-target.xml
       * @description: 위 파일 내에 name === objType 로 반환하는 element의 쿼리를 실행하여 조회대상 리스트 반환
       */
      async getReportTargetList() {
        if (isEmpty(this.reportInfo)) return;
        const { objType: name, solution, subPath } = this.reportInfo;
        return await this.fetchReportTargetData({
          name: name,
          solution: solution,
          subPath: subPath,
          loginId: this.$store.getters.getLoginId,
        });
      },
      makeNavItemsByDepth(depth) {
        return cloneObj(this.$refs.depthTreeBox[depth].getSelectedItems())
          .filter(v => v.PARENT_ID !== -1)
          .map(v => {
            v.TEXT = v.NAMETREE.replaceAll('|', '>');
            v.PARENT_ID = -1;
            v.isNav = true;
            return v;
          });
      },
      getCheckedItems(items) {
        if (this.selectedCategory === 'ipcc_ibg') {
          return items.map(item => item.ibgInfo.ibg);
        } else if (this.selectedCategory === 'ipcc_agtteam') {
          return items.map(item => item.agtteamInfo.agtteam);
        }
        return items.map(item => item.VALUE);
      },
      /**
       * 국세청용
       * stastFl: N 값 설정이 되어있을 때 사용하는 함수
       * @param targetDepth
       * @returns {Promise<void>}
       */
      async setLastDepthDataByApi(targetDepth) {
        // 마지막 뎁스 클릭 시 return
        const lastDepth = this.computedCheckBtnOptions.length;
        if (targetDepth.indexOf(lastDepth) > -1) return;
        const { chk_dept1, chk_dept2, chk_dept3, chk_dept4 } = this.target;
        const { Y, N } = this.status.stastFl;
        // 통계 대상
        let stastFl = 0; // 대상/비대상 체크
        if (Y && !N) stastFl = 1; // 대상 체크
        else if (!Y && N) stastFl = 2; // 비대상 체크
        this.setSearchParam('stastFl', stastFl);
        const payload = {
          ...this.PAGE_DATA.lastDepthApi,
          type: 'object_layout',
          stastFl: stastFl,
          obj: [...chk_dept1, ...chk_dept2, ...chk_dept3, ...chk_dept4],
        };

        const res = await this.fetchReportTargetData(payload);
        if (isSuccess(res)) {
          this.target[`v_dept${lastDepth}`] = res.data.data;
        }
      },
      /** 아이템, 속성을 이용해 속성 값 배열로 반환 */
      getValuesFromKey(items, key) {
        return items.map(item => item[key]);
      },
      /**
       * 트리박스 아이템 선택했을 때 발생하는 이벤트
       * 1. 네비게이션 만들기
       * 2. 선택한 자식 아이템 찾기
       * 3. 페이지데이터 내 lastDepthApi 존재할 시 실행
       * @param name
       * @param nextName
       * @param items
       * @param isClick 마우스 클릭(true)
       */
      setCheckedItems(name, nextName, items, isClick) {
        const values = this.getValuesFromKey(items, 'VALUE');
        const nodeIds = this.getValuesFromKey(items, 'NODE_ID') || values; // 기존 XML 에러 방어

        this.target[`chk_${name}`] = values;
        if (nextName !== undefined) {
          //Next Depth 존재 시 Next Tree 셋팅
          const navigations = items.map(v => ({
            ...v,
            isNav: true,
            PARENT_ID: -1, // 해당 아이템 트리박스 최상단으로 놓기
            TEXT: v.NAMETREE ? v.NAMETREE.replace(/\|/g, '>') : v.TEXT,
          }));
          const nextDatas = this.target[nextName].filter(v => nodeIds.find(v2 => v2 === v.PARENT_ID));
          this.target[`v_${nextName}`] = [...navigations, ...nextDatas];
        }

        //lastDepthApi 실행
        if (isClick && this.PAGE_DATA.lastDepthApi !== undefined) {
          this.setLastDepthDataByApi(name);
        }
      },
      clearChekcedItems(index) {
        while (DEPTH_NUMBERS.includes(index)) {
          this.target[`chk_dept${index}`].length = 0;
          if (this.$refs.depthTreeBox[index - 1] !== undefined) {
            this.$refs.depthTreeBox[index - 1].selectedKeys.length = 0;
          }
          index++;
        }
      },
      setClassAtConditionGrid(dxEvent) {
        if (dxEvent.columnIndex > 0) return;
        dxEvent.cellElement.classList.add('condition-row');
      },
      // 사이트, 센터가 각각 하나씩일 경우 각 트리리스트 숨김처리하고 그 이후 뎁스 트리리스트 활성화
      setDisabled(depth) {
        this.status.depth = depth;
        try {
          this.status.objKey = this.computedCheckBtnOptions.find(v => v.depth === this.status.depth)?.objKey;
        } catch (e) {
          // console.log('find to checkBtnInfo.options', e);
        }

        try {
          if (this.status.objKey === undefined) {
            this.status.objKey = this.computedCheckBtnOptions.find(v => v.report === this.init.report)?.objKey;
          }
        } catch (e) {
          // console.log('find to targetBtns', e);
        }
        this.disabledTreeBox(depth);
        // 조회기준에서 사이트와 센터 숨김처리
        for (let i = 1; i < depth; i++) {
          $(`input[value=${i}]`).closest('.col').hide();
        }
      },
      /** 조회기간 옵션 클릭 이벤트 */
      onClickPeriodOptions() {
        //공휴일
        const holiYN = this.PAGE_DATA.daysChks.find(v => v.id === 'holi').checked ? 'Y' : 'N';
        // const holiStr = '공휴일 ' + (holiYN === 'N' ? '미포함' : '포함');
        const holiStr = holiYN === 'N' ? '' : ' 공휴일';
        this.setSearchParam('holi', holiYN);

        //요일
        const checkedDays = this.PAGE_DATA.daysChks.filter(v => v.id !== 'holi' && v.checked);
        const daysAlts = checkedDays.map(v => v.alt);
        const daysValues = checkedDays.map(v => v.value);
        this.setSearchParam('days', daysValues);

        //시간
        const stH = this.getSearchParam('startTime_H');
        const stM = this.getSearchParam('startTime_M');
        const etH = this.getSearchParam('endTime_H');
        const etM = this.getSearchParam('endTime_M');
        this.setSearchParam('endTime', `${etH}${etM}`);
        this.setSearchParam('startTime', `${stH}${stM}`);

        //Input 박스 출력
        this.status.periodResult = `${daysAlts.join(' ')}${holiStr} (${stH}:${stM} ~ ${etH}:${etM})`;
      },
      /**
       * 조회기간 [일, 월, 년, 기간 선택] 옵션 하이라이트 변경
       * @param type
       */
      changeColorPeriodBtn(type) {
        this.status.periodType = type; // 현재 선택된 버튼
        let isOff = true; // 해당 타입 찾지 못 했을 경우 첫번재꺼 선택되도록 하기 위한 값
        this.$refs.periodSection.childNodes.forEach(elem => {
          const hasOn = elem.classList.contains('on');
          const sameType = elem.dataset.type === type;
          if (hasOn && sameType) {
            isOff = false;
          } else if (!hasOn && sameType) {
            isOff = false;
            elem.classList.add('on');
          } else {
            elem.classList.remove('on');
          }
        });

        if (isOff) {
          const periodBtns = this.$refs.periodSection.childNodes;
          if (periodBtns.length > 0) {
            this.onClickPeriodBtn(periodBtns.at(0).dataset.type);
          }
        }
      },
      /**
       * [일 | 월 | 년 | 기간선택] 버튼 클릭 이벤트
       * RANGE 가 아니면 현재 시점으로 기간 셋팅
       * @param index
       * @param type
       */
      onClickPeriodBtn(type) {
        // ** 1. 선택한 버튼 컬러 변경 및 periodType 셋팅
        this.changeColorPeriodBtn(type);
        // ** 2. 일, 월, 년도, 기간 클릭 시 Input 변경
        const today = moment().format('YYYY-MM-DD');
        if (['MONTH', 'YEAR'].includes(type)) {
          const tDt = today.split('-');
          this.init.currentYear = tDt[0];
          this.init.currentMonth = tDt[1];
          const funcName = 'change' + ('MONTH' === type ? 'Month' : 'Year');
          this[funcName]();
        } else {
          if (this.status.periodType === 'DAY') {
            this.status.startDt = today;
            this.status.endDt = today;
          }
          this.changeDay();
        }
      },
      getSearchParam(key) {
        return this.formData.searchParams[key];
      },
      setSearchParam(key, value) {
        this.formData.searchParams[key] = value;
      },
      /**
       * depth 별 선택된 대상 가져오기
       * 사용할 때 'input'이라 명시한 건 customList에서 가지고 온다는 걸 의미함.
       * @param depth
       * @returns {[]|*}
       */
      getSelectedTarget(depth) {
        const selectedBtnType = this.status.selectedBtnType;
        if ('input' === selectedBtnType) {
          return this.target.customList;
        } else {
          return this.target[`chk_dept${depth}`];
        }
      },
      getIpccSelectedTarget() {
        return this.getSelectedTarget(1).filter(v => v !== null);
      },
      validateMessageByParams() {
        // 기간
        const startDt = this.getSearchParam('dayStart');
        const endDt = this.getSearchParam('dayEnd');
        const isDateError = startDt > endDt;
        if (isDateError) return this.PAGE_DATA.ERR_MSG_DATE;
        // 요일
        const checkedDays = this.PAGE_DATA.daysChks.filter(v => v.checked);
        if (checkedDays.length === 0) return this.PAGE_DATA.ERR_MSG_DAYS;
        // 시간
        const startTime = this.getSearchParam('startTime');
        const endTime = this.getSearchParam('endTime');
        const isTimeError = startTime > endTime;
        if (isTimeError) return this.PAGE_DATA.ERR_MSG_TIME;

        // 대상선택
        if ('input' === this.status.selectedBtnType && this.getSelectedTarget('input').length === 0) {
          return this.PAGE_DATA.ERR_MSG_CUSTOM_TARGET;
        } else {
          const depth = this.status.depth;
          if (this.computedIsIpccCategory && this.getIpccSelectedTarget().length === 0) {
            return this.PAGE_DATA.ERR_MSG_TARGET;
          } else if (this.getSelectedTarget(depth).length === 0) {
            return this.PAGE_DATA.ERR_MSG_TARGET;
          }
        }
        return '';
      },
      /**
       * 보고서 조회 전 최종적으로 모든 파라미터 업데이트
       * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
       */
      updateAllReportParam(excelFlag) {
        const { targetQuery, solution, subPath } = this.reportInfo;
        const { configValue } = this.$_getSystemData('cti_system_type');
        this.setSearchParam('ctiType', configValue);
        this.setSearchParam('solution', solution);
        this.setSearchParam('subPath', subPath);
        this.setSearchParam('excel', excelFlag);

        //Set customObj(단일) || depthObj(뎁스별)
        this.setSearchParam('obj', []);
        this.setSearchParam('customObj', []);
        this.setSearchParam('objHeader', this.getObjHeader(this.status.selectedBtnType));

        //@TODO: dayStart, dayEnd 제거시 해당 부분 삭제
        this.setSearchParam('startDt', this.getSearchParam('dayStart'));
        this.setSearchParam('endDt', this.getSearchParam('dayEnd'));

        if ('input' === this.status.selectedBtnType) {
          this.setSearchParam('customObj', this.getSelectedTarget('input'));
        } else {
          //Set obj 1~5
          if (this.computedIsIpccCategory) {
            const selectedItems = this.getIpccSelectedTarget();
            this.setSearchParam('obj', selectedItems);
          } else {
            this.setSearchParam('obj', this.getSelectedTarget(this.status.depth));
          }

          const { maxSelectedCnt } = this.PAGE_DATA.checkBtnInfo;
          if (maxSelectedCnt !== undefined && maxSelectedCnt < this.getSearchParam('obj').length) {
            return this.$_Msg(this.PAGE_DATA.ERR_MSG_MAX_SELECTED_TARGET.replace('{n}', maxSelectedCnt));
          }
        }

        // 상세 조건
        if (this.PAGE_DATA.detailBtnInfo) {
          const { talkTime, talkTimeOpt, availTime, availTimeOpt, aniNumber } = this.target;
          // 선택된 아이템 리스트
          const selectedDetailItems = this.$refs[this.DetailObjectBox].getSelectedValues();
          this.setSearchParam('detailKey', this.PAGE_DATA.detailBtnInfo.objKey);
          this.setSearchParam('detailObj', selectedDetailItems);
          // 통화시간
          this.setSearchParam('talkTime', talkTime);
          this.setSearchParam('talkTimeOpt', talkTimeOpt);
          // 대기시간
          this.setSearchParam('availTime', availTime);
          this.setSearchParam('availTimeOpt', availTimeOpt);
          // 전화번호
          this.setSearchParam('ani', aniNumber.trim() === '' ? '' : `%${aniNumber.trim()}%`);
        }

        this.setSearchParam('depth', this.getSearchParamDepth());
        this.setSearchParam('targetQuery', targetQuery);
        this.setSearchParam('loginId', this.$store.getters.getLoginId);
        this.setSearchParam('objType', this.reportInfo.objType);
        this.setSearchParam('objKey', this.status.objKey);

        // optionItems 추가
        this.setCheckOptionItems();

        // 검색조건 조회기준이 카테고리일 경우 가장 마지막 뎁스의 objkey를 설정
        if (this.computedIsIpccCategory) {
          let newObjKey = this.computedCheckBtnOptions.slice(-1)[0].objKey;
          this.setSearchParam('objKey', newObjKey);
        }
      },
      /**
       * @description 조회대상(체크, 개별)에 대한 헤더 정보 조회
       * @param {String} type: check || input
       * */
      getObjHeader(type) {
        try {
          let options = this.computedCheckBtnOptions;
          if ('input' === type) {
            options = this.PAGE_DATA.inputBtnInfo.options;
          }

          return options.map(v => v.name);
        } catch (err) {
          return [];
        }
      },
      /**
       * 보고서 조회 기능
       * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
       * @param {Boolean} isWin 새창 여부
       */
      onSearchReport(excelFlag, isWin = false) {
        //Validation
        const errMessage = this.validateMessageByParams();
        if (errMessage.trim()) return this.$_Msg(errMessage);

        //Set Params
        this.updateAllReportParam(excelFlag);

        //조회 [popOn.checked: 새창 / else: 현재 브라우저]
        if (excelFlag === 0 && isWin) {
          this.newWindow.params = this.getReportSearchParams();
          this.status.windowPopup = true;
        } else {
          this.toggleHiddenSection(null, '');
          this.$emit('onSearchClick', this.formData.searchParams, this.getHiddenColumns());
        }
      },
      /**
       * 보고서 조회 시 파라미터로 보낼 depth 별
       * 숨겨야할 컬럼을 반환
       * status.depth가 아닌 searchParams.depth를 기준으로 반환
       * @returns {*|*[]}
       */
      getHiddenColumns() {
        const depth = this.getSearchParamDepth();
        const selectedCheckBtn = this.computedCheckBtnOptions[depth - 1];
        if (selectedCheckBtn && selectedCheckBtn.hiddenColumns) {
          return selectedCheckBtn.hiddenColumns;
        }
        return [];
      },
      getSearchParamDepth() {
        const options = this.computedCheckBtnOptions;
        let depth = options.length;
        // 마지막 뎁스
        if (this.getChkDepthTarget && this.getChkDepthTarget()) {
          return depth;
        } else if (this.status.selectedBtnType === 'input') {
          return depth;
        }

        //checked option depth
        return this.status.depth;
      },
      fmtNumber(value) {
        if (value) {
          let rtn = parseInt(value);
          return rtn.toLocaleString('ko-KR');
        }
        return 0;
      },
      /***** [START]검색조건관리 *****/
      openConditionModal(openOrClose) {
        this.status.openConditionModal = openOrClose;
      },
      async toggleUserSearchTemplate() {
        await this.selectSearchCondition();
      },
      async selectSearchCondition() {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_SEARCH_PARAM_LIST',
          data: {
            menuId: this.reportInfo.menuId,
            memberId: this.$store.getters.getLoginId,
          },
        });
        if (isSuccess(res)) this.init.searchCondition = getResData(res);
      },
      /** @description 선택한 옵션(pageData -> optionItems) 값 업데이트 */
      updateCheckOptionItem($event, index) {
        this.PAGE_DATA.optionItems[index].checked = $event.value;
      },
      /** @description 옵션(pageData -> optionItems) 값 가지고 오는 기능 */
      getCheckOptionItems() {
        return this.PAGE_DATA.optionItems.map((v, i) => {
          return { id: `option${i + 1}`, checked: v.checked };
        });
      },
      /** @description 보고서 조회 파라미터 내 셋팅 */
      setCheckOptionItems() {
        const checkOptionItems = this.getCheckOptionItems();
        if (checkOptionItems.length > 0) {
          checkOptionItems.forEach(v => vm.setSearchParam(v.id, v.checked));
        }
      },
      /**
       * 현재 셋팅 된 파라미터들 값을 search / target / status 로 분리해서 리턴
       * 검색조건관리 및 새로운창 파라미터 셋팅 시 사용
       * 해당 함수를 사용해야 컴포넌트에 값 셋팅이 가능(트리뎁스 포함)
       */
      getReportSearchParams() {
        const _that = this;
        // Search Param
        const searchParamKeys = [
          'dayStart',
          'dayEnd',
          'days',
          'holi', //날짜
          'startTime_H',
          'startTime_M',
          'endTime_H',
          'endTime_M', //시간
          'report_type', //레포트유형
        ];

        const searchParamData = searchParamKeys.reduce((acc, key) => {
          acc[key] = _that.formData.searchParams[key];
          return acc;
        }, {});

        // Status
        const statusData = {
          depth: _that.status.depth,
          selectedBtnType: _that.status.selectedBtnType,
        };

        // 커스텁(단일) 또는 뎁스 별 선택한 데이터
        let targetData = null;
        if ('input' === this.status.selectedBtnType) {
          targetData = { customList: this.target.customList };
        } else {
          targetData = DEPTH_NUMBERS.reduce((acc, number) => {
            const key = `chk_dept${number}`;
            const datasByDept = _that.target[key];
            if (datasByDept.length > 0) acc[key] = datasByDept;
            return acc;
          }, {});
        }

        return {
          search: searchParamData,
          target: targetData,
          status: statusData,
          filter: this.getSelectedReportFilterKeys(),
        };
      },
      /** @description 즐겨찾는 검색조건 저장 */
      async saveSearchCondition() {
        const title = this.$refs.searchCondition.title.trim();
        const titleMaxSize = this.$refs.searchCondition.limitLength + 1;
        const reportParams = this.getReportSearchParams();
        if (title !== '' && title.length < titleMaxSize) {
          const res = await this.CALL_REPORT_API({
            actionname: 'REPORT_SEARCH_PARAM_INSERT',
            data: {
              title: title,
              menuId: this.reportInfo.menuId,
              param: JSON.stringify(reportParams),
            },
            loading: true,
          });

          this.$_Toast(isSuccess(res) ? '검색 조건이 저장되었습니다.' : '작업실패');
          if (isSuccess(res)) {
            this.$refs.searchCondition.title = '';
            this.$refs.searchCondition.textSize = 0;
            this.status.openConditionModal = false;
            await this.selectSearchCondition();
          }
        } else {
          const validMsg = title === '' ? '조건검색명은 필수입니다.' : '50자 이하로 작성해주시기 바랍니다.';
          this.$_Toast(validMsg);
        }
      },
      /**
       * 즐겨찾는 검색조건 삭제
       * @param id
       * @returns {Promise<void>}
       */
      async onDeleteCondition(data) {
        if (data && data.id) {
          const res = await this.CALL_REPORT_API({
            actionname: 'REPORT_SEARCH_PARAM_DELETE',
            path: `/${data.id}`,
          });

          const toastMessage = isSuccess(res) ? '검색 조건이 삭제되었습니다.' : '작업실패';
          this.$_Toast(toastMessage);
          await this.selectSearchCondition();
        }
      },
      /** description: 검색조건관리 리스트 클릭 시 화면 및 파라미터 셋팅 */
      changeSectionBySearch(search) {
        // 1. 요일 및 공휴일
        const { days, holi } = search;
        this.PAGE_DATA.daysChks.forEach(v => {
          v.checked = days.indexOf(v.value) !== -1;
        });

        this.PAGE_DATA.daysChks.at(-1).checked = holi === 'Y';

        // 2. 기간 및 시간
        const _that = this;
        const alreadyUsedKeys = ['days', 'holi']; // 이미 셋팅해서 제외할 Key
        Object.entries(search).forEach(([key, value]) => {
          if (!alreadyUsedKeys.includes(key)) {
            _that.formData.searchParams[key] = value;
          }
        });

        const startDt = this.getSearchParam('dayStart');
        const endDt = this.getSearchParam('dayEnd');
        const sDt = startDt.split('-');
        const eDt = endDt.split('-');
        this.init.currentYear = eDt[0];
        this.init.currentMonth = eDt[1];
        this.status.startDt = startDt;
        this.status.endDt = endDt;
        if (startDt === endDt) {
          this.changeColorPeriodBtn('DAY');
        } else {
          const sameY = sDt[0] === eDt[0];
          const sameM = sDt[1] === eDt[1];
          const lastDd = moment(endDt).endOf('month').format('DD');
          if (sameY && startDt.indexOf('01-01') > -1 && endDt.indexOf('12-31') > -1) {
            this.changeColorPeriodBtn('YEAR');
          } else if (sameY && sameM && sDt[2] === '01' && eDt[2] === lastDd) {
            this.changeColorPeriodBtn('MONTH');
          } else {
            this.changeColorPeriodBtn('RANGE');
          }
        }
      },
      /** @description 대상 버튼(check or input) 및 조회기준 radio button 선택 */
      changeSectionByStatus(status) {
        const { depth, selectedBtnType } = status;
        this.status.selectedBtnType = selectedBtnType; // 조회대상 버튼 (check or input) 선택

        // 조회기준 라디어버튼이 있고, Depth가 여러 개 있는 트리리스트 컴포넌트인 경우
        const selectedRadio = this.computedCheckBtnOptions.find(v => v.depth === depth);
        if (selectedRadio) {
          this.$refs.targetGroupRadio.instance.option('value', depth);
        }
      },
      /** @description 조회그룹 선택 */
      changeSectionByTarget(target) {
        // 트리 박스 내 선택한 오브젝트 리스트 셋팅
        if (target) {
          if (Object.hasOwn(target, 'customList')) {
            vm.target.customList = target.customList;
          } else {
            Object.values(target).forEach((values, i) => {
              const treeBoxByDepth = vm.$refs.depthTreeBox[i];
              if (treeBoxByDepth) {
                treeBoxByDepth.getInstance().refresh();
                // treeBoxByDepth.onClickAllCheckBtn('deselectAll'); // 선택되어있던 체크박스 초기화
                // 하위컴포넌트 생성 전(렌더링 전) 체크하는 문제 때문에
                // 바로 체크하는 함수를 쓰지 않고 자식컴포넌트(depth-tree-box) selectedKeys에 담은 후
                // watch() 및 update() Hook에서 처리
                treeBoxByDepth.selectedKeys = values;
              }
            });
          }
        }
      },
      /** @description 검색 조건 셋팅 */
      handleSetSearchCondition(dxEvent) {
        if (dxEvent.columnIndex !== 0) return;
        const { title, param } = dxEvent.data;
        if ([title, param].includes(undefined)) {
          this.$_Msg('해당 조건이 정상적으로 적용되지 않았습니다.');
        }

        const jsonData = JSON.parse(param);
        const { search, target, status } = jsonData;
        this.changeSectionBySearch(search);
        this.changeSectionByStatus(status);
        this.changeSectionByTarget(target);

        // 셋팅 후 히든섹션 닫기
        this.toggleHiddenSection(null, '');

        // 리스트 박스 닫고 알림 UP
        this.$refs.popoverSearchCondition.toggleConditionPopover(false);
        this.$_Toast(`${title} 조건으로 설정되었습니다.`);
      },
      toggleHiddenSection(e, targetID, btnRef) {
        let isOn = true;
        let btnElem = null;
        // ** 1. 클릭한 버튼 컬러 변경 및 대상[그룹, 내선번호 등]일 시 값 세팅
        if (e !== null) {
          // 1. 버튼 on/off 체크
          const target = e.target;
          btnElem = target;
          if ('INPUT' === target.tagName) {
            btnElem = this.$refs[btnRef];
          } else if ('SPAN' === target.tagName) {
            btnElem = target.parentNode;
          }
          isOn = btnElem.classList.contains('on');

          // 2. 대상 버튼 클릭 시 => selectedBtnType, objKey 셋팅
          const { type } = btnElem.dataset;
          if (type !== undefined) {
            this.status.selectedBtnType = type; // Change Button Type
          }
        }

        // ** 2. 버튼 컬러 변경
        const _this = this;
        const targetBtnRefs = _this.PAGE_DATA.targetBtns.map(v => `${v.type}LayerBtn`);
        const showedBtnList = [...targetBtnRefs, 'dateLayerBtn'];
        if (this.PAGE_DATA.detailBtnInfo) {
          showedBtnList.push('detailLayerBtn');
        }

        showedBtnList.forEach(btnRef => {
          const refBtn = _this.$refs[btnRef][0] || _this.$refs[btnRef];
          const child = refBtn.lastChild;
          const onOff = !isOn && btnElem === refBtn ? 'on' : 'off';
          refBtn.classList = _this.styles[`${onOff}LayerBtn`];
          child.classList = _this.styles[`${onOff}UpDown`];
        });

        // ** 3. 레이어 드롭다운 열고 닫는 로직
        const HIDDEN_CLASS = 'hidden';
        showedBtnList.forEach(v => {
          const layerId = v.replace('LayerBtn', 'HiddenLayer');
          const layerRef = _this.$refs[layerId];
          if (targetID === layerId) {
            layerRef.classList.toggle(HIDDEN_CLASS);
            if (v === 'checkLayerBtn') _this.refreshTreeBoxList();
          } else if (layerRef.classList.contains(HIDDEN_CLASS) === false) {
            layerRef.classList.add(HIDDEN_CLASS);
          }
        });
      },
      /**
       * 컴포넌트 내 블라인드 처리가 없어지지 않아
       * Refresh 시키는 로직 추가
       */
      refreshTreeBoxList() {
        this.$refs.depthTreeBox.forEach(v => {
          v.refreshTreeList();
        });
      },
      setLayerPosition(elemId, left) {
        if (left < 1) return;
        let isFound = false;
        const headerNodes = document.head.children;
        const headerLength = headerNodes.length;
        for (let i = headerLength - 1; i > -1; i--) {
          const node = headerNodes[i];
          if (node.tagName === 'STYLE' && node.getAttribute('layer') === elemId) {
            node.innerHTML = `#${elemId}::after { left: ${left}px; }`;
            isFound = true;
            break;
          }
        }

        if (isFound) return;
        const rootAfterStyle = document.createElement('style');
        rootAfterStyle.setAttribute('layer', elemId);
        rootAfterStyle.innerHTML = `#${elemId}::after { left: ${left}px; }`;
        document.head.appendChild(rootAfterStyle);
      },
      onChangeReportType(e) {
        this.setSearchParam('report_type', e.value);
      },
      changeDay() {
        const isDay = 'DAY' === this.status.periodType;
        let startDt = this.status.startDt;
        let endDt = isDay ? startDt : this.status.endDt;
        this.setSearchParam('dayStart', moment(startDt).format('YYYYMMDD'));
        this.setSearchParam('dayEnd', moment(endDt).format('YYYYMMDD'));
        //TODO: dayStart, dayEnd는 삭제 예정
        this.setSearchParam('startDt', moment(startDt).format('YYYYMMDD'));
        this.setSearchParam('endDt', moment(endDt).format('YYYYMMDD'));
      },
      changeMonth() {
        const periodYear = this.init.currentYear;
        const periodMonth = this.init.currentMonth;
        const YYYYMM = `${periodYear}${periodMonth}`;
        const startDt = `${YYYYMM}01`;
        const endDt = moment(startDt).endOf('month').format('YYYYMMDD');
        this.setSearchParam('dayStart', startDt);
        this.setSearchParam('dayEnd', endDt);
        //TODO: dayStart, dayEnd는 삭제 예정
        this.setSearchParam('startDt', startDt);
        this.setSearchParam('endDt', endDt);
      },
      changeYear() {
        const periodYear = this.init.currentYear;
        const startDt = `${periodYear}0101`;
        const endDt = `${periodYear}1231`;
        this.setSearchParam('dayStart', startDt);
        this.setSearchParam('dayEnd', endDt);
        //TODO: dayStart, dayEnd는 삭제 예정
        this.setSearchParam('startDt', startDt);
        this.setSearchParam('endDt', endDt);
      },
      onChangePeriod(e, type) {
        const { periodType } = this.status;
        if (['DAY', 'RANGE'].includes(periodType)) {
          this.changeDay();
        } else if ('MONTH' === periodType && ['MONTH', 'YEAR'].includes(type)) {
          this.changeMonth();
        } else if ('YEAR' === periodType) {
          this.changeYear();
        }
      },
      /**
       * 대상 > 조회기준 라디오 버튼 값 변경 시 실행되는 함수
       * @param e
       */
      changeRadioInGroupSection(e) {
        const depth = e ? e.value : 1;
        const options = this.PAGE_DATA.checkBtnInfo?.options;
        this.status.depth = depth;

        try {
          let objKey = options.find(v => v.depth === this.status.depth)?.objKey;
          if (objKey === undefined) {
            objKey = options.find(v => v.report === this.init.report)?.objKey;
          }
          this.status.objKey = objKey;
        } catch (e) {
          // console.log('find to checkBtnInfo.options', e);
        }
        this.setSearchParam('objKey', depth);
        this.disabledTreeBox(depth);
      },
      /**
       * 현재 선택한 뎁스보다 낮은 경우 트리박스 비활성화
       * @param depth
       */
      disabledTreeBox(depth) {
        const _that = this;
        DEPTH_NUMBERS.forEach(v => {
          _that.target[`disabled${v}`] = !(v <= depth);
        });
      },
      /**
       * input타입(개별) 버튼 item(내선번호, 발신번호 등)
       * 값 입력 후 추가하여 Grid에 셋팅하는 함수
       * @returns {*}
       */
      onClickAddCustomItems() {
        const data = {};
        this.PAGE_DATA.inputBtnInfo.options.forEach((v, i) => {
          const name = `item${i + 1}`;
          data[name] = vm.target[`v_${name}`];
        });

        //VALID
        if (Object.values(data).filter(v => v.trim() !== '').length === 0) {
          return this.$_Msg('값을 입력해주시기 바랍니다.');
        }

        //CLEAR
        this.PAGE_DATA.inputBtnInfo.options.forEach((v, i) => {
          const name = `item${i + 1}`;
          vm.target[`v_${name}`] = '';
        });

        //ADD
        this.target.customList.push(data);
      },
      /**
       * input타입 버튼 item(내선번호, 발신번호 등) 삭제
       * @param index
       */
      onDeleteCustom(index) {
        this.target.customList.splice(index, 1);
      },
      async mountedSetNewWindow(popupItems) {
        if (![null, undefined].includes(popupItems)) {
          // 헤더 및 메뉴 제거
          this.status.popupmode = true;
          $('#wrap > header').remove();
          $('#wrap > aside').remove();
          $('#wrap > main > .content').css('margin-left', 0);
          //컴포넌트 생성 전 셋팅 막기 위해 [nextTick] 사용
          await this.$nextTick(() => {
            const { options, params } = JSON.parse(popupItems);
            const { search, status, target } = options;
            // UI컴포넌트(기간, 대상, 보고서유형) 옵션 변경
            this.changeSectionBySearch(search);
            this.changeSectionByStatus(status);
            this.changeSectionByTarget(target);
            // FormData 셋팅
            this.formData.searchParams = params;
          });

          this.$emit('onSearchClick', this.formData.searchParams);
        }
      },
      mountedSetOptionByLocalStorage() {
        if (Object.hasOwn(this.reportInfo, 'reportId')) {
          const reportId = this.reportInfo?.reportId;
          const storedReportParams = localStorage.getItem(`ECSS_RPT_${reportId}`);
          this.$nextTick(() => {
            if (storedReportParams) {
              const { search, status, target } = JSON.parse(storedReportParams);
              // UI컴포넌트(기간, 대상, 보고서유형) 옵션 변경
              this.changeSectionBySearch(search);
              this.changeSectionByStatus(status);
              if (this.PAGE_DATA.useStorageData) this.changeSectionByTarget(target);
            } else {
              this.changeColorPeriodBtn('DAY');
            }
          });
        }
      },
      initPageData() {
        return {
          /***** components setting start ****/
          checkBtnInfo: null,
          inputBtnInfo: null,
          detailBtnInfo: null,
          optionItems: [],
          //OPTIONS
          useStorageData: true, //로컬스토리지에 저장되어 있는 조회그룹 사용할지 여부
          showReportType: true, //보고서 타입 보이기 여부
          multiDownload: [], // 보고서 일괄 다운로드 (자신 ID도 적어야 함)
          /** MasterQuery
           * {
           *     name: sql name 속성 값
           *     type: "master-query"
           *     objKey: 파라미터 objKey 값
           * }
           */
          lastDepthApi: undefined,
          detail: {
            timeOpts: ReportManager.getDetailTimeOpts(),
          },
          targetBtns: ReportManager.getTargetBtns(),
          daysChks: ReportManager.getDayOfTheWeek(),
          /***** components setting end by report.js ****/

          //STYLE
          dxStyled: 'outlined',
          //ERROR_MESSAGE
          ERR_MSG_DATE: '선택한 날짜를 확인해주시기 바랍니다.',
          ERR_MSG_DAYS: '선택한 요일을 확인해주시기 바랍니다.',
          ERR_MSG_TIME: '선택한 시간을 확인해주시기 바랍니다.',
          ERR_MSG_TARGET: '대상을 하나 이상 선택해주시기 바랍니다.',
          ERR_MSG_CUSTOM_TARGET: '대상을 추가해주시기 바랍니다.',
          ERR_MSG_MAX_SELECTED_TARGET: '대상은 최대 {n}개까지 선택 가능합니다.',
        };
      },
      async setPageData() {
        const { solution, subPath, reportId } = this.reportInfo;
        try {
          let pageData = '';
          if (Object.hasOwn(this.reportInfo, 'pageData')) {
            pageData = this.reportInfo.pageData;
          } else if (Object.hasOwn(this.reportInfo, 'pagedata')) {
            //TODO: 분리 작업 전 'pagedata' 로 받아오고 있었으며, 추후 삭제 예정
            pageData = this.reportInfo.pagedata;
          }
          const pageDataFunc = new Function(`return ${pageData.trim()}`).bind(this)();
          this.PAGE_DATA = { ...this.initPageData(), ...pageDataFunc };
          // await Object.entries(pageDataFunc).forEach(([key, value]) => (vm.PAGE_DATA[key] = value));
        } catch (e) {
          const xmlPath = `${solution}/${subPath ? subPath + '/' : ''}${reportId}.xml`;
          this.$_Msg(`보고서 XML 파일(${xmlPath}) <pageData> 값을 확인해주시기 바랍니다.<br>${e}`);
        }
      },
      async toggleXmlModal(toggleBool = true) {
        if (toggleBool) {
          this.modal.xmlHistories = await this.asyncGetXmlHistoryByReportId(this.reportInfo?.reportId);
        }
        //toggleBool의 경우 boolean이 아닐 수도 있어서 아래와 같이 처리
        this.modal.isOpenXmlModal = toggleBool ? true : false;
      },
      async asyncGetXmlHistoryByReportId(reportId) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_XML_HISTORY_LIST',
          path: `/${reportId}`,
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
        return [];
      },
      async saveXmlModal() {
        const { cmEditor: reportXml, description } = this.$refs.xmlEditor;
        const validatePageDataRet = this.$refs.xmlEditor.validatePageData();
        if (validatePageDataRet.isError) {
          this.$_Msg(validatePageDataRet.msg);
          return false;
        }

        if (description.length > 100) {
          return this.$_Msg('설명은 100자 이내로 작성해주시기 바랍니다.');
        }

        //XML, 설명, 레포트 ID
        const params = {
          xmlData: reportXml,
          reportId: this.reportInfo?.reportId,
          description: description,
          useSqlPass: false,
        };

        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_XML_UPDATE',
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        const { resCode, resMsg } = res.data.header;
        if (isSuccess(res)) {
          await this.successSaveModal();
        } else if (resCode !== 'R002') {
          //TODO: R002 는 쿼리가 틀렸을 시 보내는 코드이며, ErrorCode 작성 시 변경 필요
          this.$_Msg(resMsg);
        } else if (resCode === 'R002' && (await this.$_Confirm(`${resMsg} 강제로 저장시키겠습니까?`))) {
          const forceRes = await this.CALL_REPORT_API({
            actionname: 'REPORT_XML_UPDATE',
            data: { ...params, useSqlPass: true },
            loading: true,
          });
          if (isSuccess(forceRes)) await this.successSaveModal();
        }
      },
      /**
       * XML 편집 모달 저장 성공 시 메시지 팝업 이후 화면 reload
       * @returns {Promise<void>}
       */
      async successSaveModal() {
        await this.$_Msg('XML 파일이 편집되었습니다.');
        location.reload();
      },
      setDetailBtnInfo() {
        this.PAGE_DATA.detailBtnInfo = this.PAGE_DATA.detailBtn;
      },
      setTargetBtnInfo() {
        // set checkBtnInfo
        this.PAGE_DATA.checkBtnInfo = this.PAGE_DATA.targetBtns.find(v => v.type === 'check');
        if (this.PAGE_DATA.checkBtnInfo) {
          const maxDepth = this.computedCheckBtnOptions.length - 1;
          this.PAGE_DATA.checkBtnInfo.options = this.computedCheckBtnOptions.map((v, vi) => {
            return { ...v, hasNext: v.hasNext !== false && vi < maxDepth };
          });
        }
        // set inputBtnInfo
        this.PAGE_DATA.inputBtnInfo = this.PAGE_DATA.targetBtns.find(v => v.type === 'input');
      },
      setPageLayout() {
        if (!this.reportInfo) return;
        const {
          // 기간
          searchTypeDay,
          searchTypeMonth,
          searchTypeYear,
          searchTypeFromto,
          // 보고서유형
          reportTypeDay,
          reportTypeMonth,
          reportTypeHour,
          reportTypeMin30,
          reportTypeMin15,
          reportTypeDayhour,
        } = this.reportInfo;

        //기간
        const periodDatas = {
          DAY: searchTypeDay,
          MONTH: searchTypeMonth,
          YEAR: searchTypeYear,
          RANGE: searchTypeFromto,
        };

        const dateBtnOptions = [
          { name: '일', type: 'DAY' },
          { name: '월', type: 'MONTH' },
          { name: '년', type: 'YEAR' },
          { name: '기간선택', type: 'RANGE' },
        ];

        this.setTimeList();
        this.setPriodMonthAndYear();
        this.init.periodBtns = dateBtnOptions.filter(btn => periodDatas[btn.type]);

        //유형
        const reportTypeDatas = {
          daily: reportTypeDay,
          monthly: reportTypeMonth,
          hour: reportTypeHour,
          i15: reportTypeMin30,
          i30: reportTypeMin15,
          daytimes: reportTypeDayhour,
        };

        this.init.reportTypeOpts = this.init.v_reportTypeOpts.filter(opt => isTrue(reportTypeDatas[opt.value]));
        this.setPageData();
        this.setTargetBtnInfo();
        this.setDetailBtnInfo();

        this.status.disabledDays = !isTrue(this.reportInfo.conditionWeeks);
        this.status.disabledTimes = !isTrue(this.reportInfo.conditionTimes);
        this.init.report = this.reportInfo.objType;
      },
      /** @description : IPCC 트리 데이터 가져오는 메서드 */
      async mountedSetIpccDataList(reportType) {
        let actionname = '';
        // 보고서 타입에 따라 조회기준 API 변경
        if (reportType === 'ipcc_ibg') actionname = 'CC_IBG_CATEGORY_LIST';
        else if (reportType === 'ipcc_agtteam') actionname = 'CC_AGTTEAM_CATEGORY_LIST';

        //API 호출
        const res = await this.CALL_CC_API({
          actionname: actionname,
          data: { params: { sort: '+id' } },
          loading: true,
        });

        if (isSuccess(res)) {
          this.categoryDatas = getResData(res);
        }
      },

      initMounted() {
        this.changeRadioInGroupSection();
        this.onClickPeriodOptions();
        this.setDeptList();
        this.setDetailList();

        const popupItems = sessionStorage.getItem(window.name);
        if (popupItems) {
          // ** 새창보기 (sessionStorage Name 값이 Null이 아닐 시 동작)
          this.mountedSetNewWindow(popupItems);
        } else {
          //이전 조회 이력이 있다면 조회했던 옵션으로 셋팅한다.
          //조회했던 옵션은 로컬스토리지에 "RPT_MID_${reportId}"로 저장되어있다.
          this.mountedSetOptionByLocalStorage();
        }

        //Ipcc 처리
        this.mountedSetIpccOption();
        // set XML Modal Info
        this.mountedSetXmlModalInfo();
      },
      mountedSetXmlModalInfo() {
        // set XML Modal Info
        if (this.reportInfo) {
          const { menuNm, reportId } = this.reportInfo;
          this.modal.editorOption.reportNm = menuNm;
          this.modal.editorOption.reportId = reportId;
        }
      },
      ctiTooltip(title) {
        return (
          '- CTI 기준 : CTI 데이터 기준으로 검색대상 선택<br/>' +
          `- ${title} 카테고리 기준 : 관리 카테고리 기준으로 검색대상 선택 (보고서 > 설정 > ${title} 카테고리 관리)`
        );
      },
      mountedSetIpccOption() {
        if (this.PAGE_DATA.targetBtns[0].isIPCC == true) {
          this.firstBoxHiding = false;
          if (this.reportInfo) {
            if (this.reportInfo.objType === 'IPCC_IBG') {
              this.selectShowTreeList = this.selectShowTreeList.filter(v => v.value !== 'ipcc_agtteam');
              this.mountedSetIpccDataList('ipcc_ibg');
              this.tooltip.contents = this.ctiTooltip('인입그룹');
            } else if (this.reportInfo.objType === 'IPCC_AGTTEAM') {
              this.selectShowTreeList = this.selectShowTreeList.filter(v => v.value !== 'ipcc_ibg');
              this.mountedSetIpccDataList('ipcc_agtteam');
              this.tooltip.contents = this.ctiTooltip('팀그룹');
            }
          }
        }
      },
    },

    created() {
      vm = this;
      this.setPageLayout();
    },
    async mounted() {
      this.initMounted();
    },
    destroyed() {},
  };
</script>

<style scoped>
  #categoryTooltip {
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 22px;
    color: #808080;
  }

  .container {
    background-color: #fff;
    margin-bottom: 0px;
  }

  .container-top-wrap {
    margin: 20px 0 10px 0;
  }

  .search-top-wrap > div {
    display: inline-block;
  }

  .search-top-wrap > div:not(:last-child) {
    margin-right: 5px;
  }

  .search-top-wrap > div:first-child {
    margin-right: 20px;
  }

  .container-top-wrap .search-bottom-wrap {
    margin-top: 30px;
  }

  .search-bottom-wrap .search-bottom-top-wrap {
    font-size: 0.95em;
  }

  .search-bottom-wrap .search-bottom-bottom-wrap {
    margin-top: 5px;
  }

  .search-bottom-bottom-wrap {
    position: relative;
  }

  .search-bottom-bottom-wrap > div {
    display: inline-block;
  }

  .search-bottom-bottom-wrap .search-bottom-left-wrap > div {
    display: inline-block;
  }

  .search-bottom-bottom-wrap .search-bottom-left-wrap > div:not(:first-child) {
    margin-left: 5px;
  }

  .search-bottom-bottom-wrap .search-bottom-right-wrap {
    position: absolute;
    right: 0;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-5 {
    padding-bottom: 5px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .pb-20 {
    padding-bottom: 20px;
  }
  .mr-0 {
    margin-right: 0 !important;
  }
  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px !important;
  }

  .mr-15 {
    margin-right: 15px !important;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .mr-20 {
    margin-right: 20px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .w150 {
    width: 150px;
  }

  .pointer {
    cursor: pointer;
  }

  .p-20 {
    padding: 20px;
  }

  .py-08 {
    padding-top: 0.8px;
    padding-bottom: 0.8px;
  }

  .border-top-1px {
    border-top: 1px solid #ebebeb;
  }

  .inline {
    display: inline;
  }

  .flex {
    display: flex;
  }

  .single-label {
    color: #353535;
    font-size: 12px;
    margin-right: 10px;
  }

  .icon-white {
    color: #fff;
  }

  .dx-outline {
    border: 1px solid #dcdcdc !important;
    border-radius: 4px;
    background-color: #fbfbfb !important;
    color: #aaaaaa;
  }

  .top2 {
    position: relative;
    top: 2px;
  }

  .top3 {
    position: relative;
    top: 3px;
  }

  .top4 {
    position: relative;
    top: 4px;
  }

  .excel {
    top: -2.2px;
  }

  .hidden {
    display: none;
  }

  .fs-12 {
    font-size: 12px;
  }

  .table_list {
    border-top: 0px solid #999 !important;
  }
</style>
<style>
  .dx-tag-content {
    padding: 3px 30px 3px 8px !important;
  }

  #conditionGrid .dx-state-hover > .condition-row:hover {
    color: #6ab0b2 !important;
    cursor: pointer !important;
  }
</style>
