<template>
	<div class="boxPadding" style="max-width: 70%">
		<!-- 타이틀 | 카운트 | 서치 -->
		<div class="inner-search search-view mar_to0">
			<div class="ly-gp02" style="padding-left: 5px; padding-right: 5px">
				<div ref="boxTitle">{{ title }}</div>
				<div>
					총 <span class="col_blue2">{{ countSelectedItem }}</span
					>개
				</div>
			</div>

			<!-- 트리 리스트 -->
			<div class="depth-tree-box">
				<DxTreeList
					ref="dxTreeList"
					height="400px"
					key-expr="VALUE"
					parent-id-expr="PARENT_ID"
					:root-value="-1"
					:show-borders="true"
					:show-row-lines="true"
					:auto-expand-all="true"
					:data-source="treeDatas"
					:column-auto-width="true"
					:show-column-lines="false"
					:show-column-headers="false"
				>
					<DxSearchPanel :visible="true" :highlightSearchText="true" placeholder="검색어를 입력해주세요." />
					<DxSelection :recursive="treeOption.recursive" :mode="treeOption.mode" />
					<DxPaging :enabled="true" :page-size="10000" />
					<DxColumn data-field="TEXT" />
				</DxTreeList>
			</div>
		</div>

		<!-- 전체 선택|해제 -->
		<div class="alR">
			<button class="txt on" @click="onClickAllCheckBtn('selectRows')">
				전체선택
			</button>
			/
			<button class="txt" @click="onClickAllCheckBtn('deselectRows')">
				전체해제
			</button>
		</div>
	</div>
</template>
<script>
import { DxTreeList, DxPaging, DxSelection, DxColumn, DxSearchPanel } from 'devextreme-vue/tree-list';

export default {
	components: {
		DxTreeList,
		DxPaging,
		DxColumn,
		DxSelection,
		DxSearchPanel,
	},
	watch: {
		selectedKeys(values) {
			if (this.selectedKeys.length > 0) {
				this.selectRows(values);
			}
		},
	},
	props: {
		title: {
			type: String,
			default: () => '',
		},
		treeDatas: {
			type: Array,
			default: () => [],
		},
		dataName: {
			type: String,
			default: () => undefined,
		},
		nextDataName: {
			type: String,
			default: () => undefined,
		},
		setCheckedItems: {
			type: Function,
			default: () => undefined,
		},
		removeSelectedKeys: {
			type: Function,
			default: () => undefined,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			treeOption: {
				recursive: true,
				stylingMode: 'outlined',
				mode: 'multiple',
			},
			selectedKeys: [],
		};
	},
	computed: {
		countSelectedItem() {
			return this.treeDatas.filter(v => !v.isNav).length.toLocaleString('ko-KR');
		},
	},
	updated() {
		if (this.selectedKeys.length > 0) {
			this.selectRows(this.selectedKeys);
		}
	},
	methods: {
		//Func
		getInstance() {
			return this.$refs['dxTreeList'].instance;
		},
		selectRows(values) {
			this.getInstance().selectRows(values, true);
		},
		onClickAllCheckBtn(funcName) {
			// selectAll, deselectAll, selectRows, deselectRows
			const dataGrid = this.getInstance();
			const values = dataGrid.getVisibleRows().map(v => v.key);
			let preValues = [];
			try {
				if (funcName === 'selectRows') {
					preValues = this.getInstance()
						.getSelectedRowsData('all')
						.map(v => v.VALUE);
				}
				dataGrid[funcName](values, ...preValues);
			} catch (e) {
				console.log(e);
			}
		},
		getSelectedItems() {
			return this.getInstance().getSelectedRowsData('all');
		},
		getSelectedValues() {
			return this.getSelectedItems().map(v => v.VALUE);
		},
	},
};
</script>
<style scoped>
button.txt {
	color: dodgerblue;
}

button.on {
	color: #00c3c8;
}

.boxPadding {
	padding: 12px 5px;
}

.depth-tree-box {
	min-width: 280px;
	margin-bottom: 10px;
	padding: 1px 0 0 0;
	border: 0.5px solid #ececec;
}
</style>
<!-- Global -->
<style>
.depth-tree-box .dx-datagrid-header-panel {
	margin: 0;
	padding: 10px;
}

.depth-tree-box .dx-toolbar .dx-texteditor {
	width: 100% !important;
}

.depth-tree-box .dx-toolbar .dx-toolbar-after {
	left: -10px;
	padding-left: 0px;
	width: 95% !important;
}

.depth-tree-box .dx-placeholder {
	left: 16px !important;
}
</style>
