<template>
	<div class="search-area">
		<form name="search" method="get">
			<div class="srch-bar">
				<label for="totSrch">통합검색</label>
				<input
					type="text"
					id="totSrch"
					class="total-search"
					autocomplete="off"
					placeholder="메뉴검색"
					v-model="searchText"
					@focus="onInputFocus"
					@keyup="searchValueChanged"
					@change="searchValueChanged"
				/>
				<button type="button" class="del-keyword">
					<span>키워드 삭제</span>
				</button>

				<button type="button" class="btn-search" title="검색">
					<span>검색</span>
				</button>
			</div>

			<!-- 자동완성 layer -->
			<div id="AutoCompLyr" class="keyword-area">
				<div class="top_back_btn" @click="closeKeywordArea" v-if="$store.getters.getIsMobile">
					뒤로가기
				</div>
				<div class="keyword-cont">
					<div class="keyword-list scrollbar-dynamic">
						<ul class="auto-on">
							<template v-for="(item, index) in searchedData">
								<li :key="index">
									<a style="cursor:pointer" @click="goMenuUrl(item)">
										{{ dept1Menu(dept2Menu(item.parentId).parentId).menuNm }}
										>
										{{ dept2Menu(item.parentId).menuNm }}
										>
										<b>{{ item.menuNm }} </b>
									</a>
								</li>
							</template>
							<div v-if="searchedData.length === 0">
								<li>
									찾고자 하는 메뉴가 없습니다.
								</li>
							</div>
						</ul>
<!--						<ul class="auto-off" style="display: none;">-->
<!--							<li class="nothing">-->
<!--								찾고자 하는 메뉴를 자동 완성해 드립니다.-->

<!--								<a href="javascript:;" class="auto-complete">자동완성 켜기</a>-->
<!--							</li>-->
<!--						</ul>-->
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchText: '',
			searchedData: [],
		};
	},
	computed: {
		getMenuList() {
			return this.$store.getters.getMenuList;
		},
	},
	methods: {
		searchValueChanged() {
			this.$log.debug(this.searchText);
			this.searchedData = [];
			if (this.searchText.trim() !== '') {
				this.searchedData = this.getMenuList.filter(d => d.menuNm.includes(this.searchText) && d.menuDepth === 3);
			}
		},
		dept3Menu(path) {
			return this.$store.getters.getMenuList.find(d => d.pageUrl === path);
		},
		dept2Menu(parentId) {
			return this.$store.getters.getMenuList.find(d => d.id === parentId && d.menuDepth === 2);
		},
		dept1Menu(parentId) {
			return this.$store.getters.getMenuList.find(d => d.id === parentId && d.menuDepth === 1);
		},
		goMenuUrl(menu) {
			this.searchText = '';

			if (menu.menuTypeCd === 5) {
				let obj = {};

				if (menu.pageUrl === 'REPORT') {
					obj.path = `/reportmain/${menu.id}`;
				}

				this.$router.push(obj);

			} else {
				this.$router.push(menu.pageUrl);
			}
		},
		onInputFocus() {
			const element = document.querySelector(".total-search");
			if (element.value.trim() === '') {
				document.querySelector('.menu .srch-bar').classList.add('show');

				const keywordArea = document.querySelector('.keyword-area');
				keywordArea.style.display = 'block';

			} else {
				document.querySelector('.menu .del-keyword').style.display = 'block';
				element.dispatchEvent(new Event('input'));
			}

			const srchDetailLyr = document.querySelector('#SrchDetailLyr');
			srchDetailLyr.style.display = 'none';
			const btnDetail = document.querySelector('.menu .btn-detail');
			btnDetail.classList.remove('open');

			const srchDetailLyrElements = document.querySelectorAll('#SrchDetailLyr a, #SrchDetailLyr button, #SrchDetailLyr input');
			for (let i = 0; i < srchDetailLyrElements.length; i++) {
				srchDetailLyrElements[i].setAttribute('tabIndex', '-1');
			}

		},
		closeKeywordArea() {
			const keywordArea = document.querySelector('.keyword-area');
			const element = document.querySelector(".total-search");
			keywordArea.style.display = 'none';
			element.value = '';
		},
	},
};
</script>