<template>
	<div class="container" id="container">
		<div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="cusmain-table-wrap">
				<component
					:ref="searchBoxComponent"
					:newPopupUrl="url"
					:reportInfo="reportInfo"
					:pageLoaded="normalized"
					:is="searchBoxComponent"
					:getChkDepthTarget="getChkDepthTarget"
					:getSelectedReportFilterKeys="getSelectedReportFilterKeys"
					@onSearchClick="onSearchClick"
				/>
				<reportGrid
					ref="grid"
					:is="gridComponent"
					:pageData="pageData"
					:columns="gridColumns"
					:pageLoaded="normalized"
					:reportInfo="reportInfo"
					:columnsDesc="gridColumnDescs"
					:getReportLocalStorage="getReportLocalStorage"
					@setReportLocalStorage="setReportLocalStorage"
					@onSearchReport="onSearchReport"
					@onClickLinkInGrid="onClickLinkInGrid"
				/>
			</div>

			<DxPopup
				:option="{
					title: '상세 이력',
					width: '1400',
					height: '700',
					minWidth: null,
					minHeight: null,
					useSaveBtn: false,
				}"
				:isOpen="modal.isOpen"
				:toolbarYn="false"
				@closeModal="onClickToggleModal(false)"
			>
				<template #content>
					<HistoryPopupContent
						ref="historyPopupContent"
						:targetGridColumns="gridColumns"
						:reportRowData="modal.reportRowData"
						:historyColumns="modal.historyColumns"
						:historyDatas="modal.historyDatas"
					/>
				</template>
			</DxPopup>
		</div>
	</div>
</template>

<script>
import ReportSearchBox from '@/components/report/search-box';
import ReportGrid from '@/components/report/datareport-grid.vue';
import { getResData, isEmpty, isSuccess } from '@/plugins/common-lib';
import DxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
import HistoryPopupContent from '@/components/report/popup/history-popup-content.vue';
import XmlEditor from '@/pages/report/config/modal-xml-editor-update.vue';
import moment from 'moment';

export default {
	components: {
		XmlEditor,
		DxPopup,
		ReportSearchBox,
		ReportGrid,
		HistoryPopupContent,
	},
	props: {},
	watch: {
		/* 동일한 컴포넌트로 이동시 페이지의 기본 변수 초기화 */
		'$route.path': async function () {
			console.log('** watch[wizard-report-view] -> $route.path');
			this.searchBoxComponent = ''; //검색조건 컴포넌트를 바인딩 제거 후 데이터 새로 세팅 후 재 바인딩
			this.gridComponent = '';
			this.pageLoaded = false;
			this.reportId = this.$route.params.menuid;
			await this.init();
			this.searchBoxComponent = 'ReportSearchBox';
			this.gridComponent = 'ReportGrid';
		},
	},
	data() {
		return {
			url: '',
			reportId: -1,
			reportNm: '',
			reportInfo: undefined,
			//Util
			pageLoaded: false, // 페이지 갱신 여부
			//SearchComponent
			searchBoxComponent: 'ReportSearchBox', //검색조건창의 컴포넌트명
			pageData: '', //보고서 XML 파일 내 <pageData> 태그의 데이터
			//GridComponent
			gridComponent: 'ReportGrid',
			gridColumns: [], //컬럼 리스트(멀티헤더 포함)
			gridColumnDescs: [], //컬럼 리스트 설명 (멀티헤더 비포함)
			//historyModal
			modal: {
				isOpen: false,
				reportRowData: [],
				historyColumns: [],
				historyDatas: [],
			},
		};
	},
	computed: {
		normalized() {
			return this.pageLoaded;
		},
	},
	methods: {
		/**
		 * 보고서 페이지 및 컴포넌트 초기화
		 * @returns {Promise<void>}
		 */
		async init() {
			// 보고서 메뉴 조회
			let res = await this.CALL_REPORT_API({
				actionname: 'REPORT_MENU_INFO',
				data: { menuId: Number(this.$route.params.menuid) },
				loading: true,
			});

			if (isSuccess(res)) {
				const resData = getResData(res)[0];
				this.reportNm = resData.menuNm;
				this.reportId = resData.reportId;
				this.pageData = resData.pageData;
				const columns = resData.columns.filter(col => col.visible);
				this.gridColumns = columns;
				this.gridColumnDescs = columns;
				this.reportInfo = resData;
			} else {
				this.$_Msg('보고서 정보를 찾지 못 했습니다.');
				return false;
			}

			// 그리드 멀티헤더 컬럼 처리
			this.gridColumns = this.getReplaceMultiHeaderColumn(this.gridColumns);
			// 새창조회 시 사용될 URL(커스텀 보고서인 경우 ID가 존재하지 않아 URL로 처리)
			this.url = this.$route.params.menuid || this.$route.fullPath;
			// 컴포넌트(SearchBox, Grid) 갱신
			this.pageLoaded = true;
		},
		getPageData() {
			return this.$refs.ReportSearchBox.PAGE_DATA;
		},
		/**
		 * date-column-report 보고서 커스텀 Dev-Extreme 형태 컬럼 만들기
		 */
		makeDateColumn(caption, dataField, multiHeaderNm, description) {
			return {
				width: 80,
				visible: true,
				align: 'center',
				caption: caption,
				format: 'fmNumber',
				description: description,
				dataField: dataField,
				multiHeaderNm: multiHeaderNm,
			};
		},
		/**
		 * 조회 기간을 일자별로 컬럼 생성 및 리턴
		 * 3개월 초과 시 false 리턴
		 * (ex: '2021-01-01', '2021-01-02', ...)
		 * @param params: 조회 시 파라미터
		 * @returns {*[]|boolean}
		 */
		getPivotDataAndUpdateColumns(params) {
			const startDt = moment(params.dayStart);
			const endDt = moment(params.dayEnd);

			const pivotParam = [];
			const customColumns = [];
			const { report_type } = params;
			let cloneDt = startDt.clone();
			if ('monthly' === report_type) {
				// 월별
				const diff = endDt.diff(startDt, 'months');
				if (diff > 24) {
					this.$_Msg('조회 기간은 2년 이내로 설정해 주세요.');
					return false;
				}

				while (cloneDt.isSameOrBefore(endDt)) {
					const ym = cloneDt.format('YYYY-MM');
					const pivotField = ym.replace(/-/g, '');
					const ymDatas = ym.split('-');
					const yyyy = ymDatas[0];
					const mm = ymDatas[1];
					pivotParam.push(pivotField);
					customColumns.push(this.makeDateColumn(`${mm}월`, `'${pivotField}'`, `${yyyy}년`, ym));
					cloneDt.add(1, 'months');
				}
			} else if ('daily' === report_type) {
				// 일별 [YYYYMMDD]
				const diff = endDt.diff(startDt, 'days');
				if (diff > 92) {
					this.$_Msg('조회 기간은 3개월 이내로 설정해 주세요.');
					return false;
				}

				while (cloneDt.isSameOrBefore(endDt)) {
					const ymd = cloneDt.format('YYYY-MM-DD');
					const pivotField = ymd.replace(/-/g, '');
					const ymdDatas = ymd.split('-');
					const yyyy = ymdDatas[0];
					const mm = ymdDatas[1];
					const dd = ymdDatas[2];
					pivotParam.push(pivotField);
					customColumns.push(this.makeDateColumn(`${dd}일`, `'${pivotField}'`, `${yyyy}년 ${mm}월`, ymd));
					cloneDt.add(1, 'days');
				}
			} else {
				const diff = endDt.diff(startDt, 'months');
				if (diff > 3) {
					this.$_Msg('조회 기간은 3개월 이내로 설정해 주세요.');
					return false;
				}

				const sHH24 = Number(params.startTime.substring(0, 2));
				const eHH24 = Number(params.endTime.substring(0, 2));
				while (cloneDt.isSameOrBefore(endDt)) {
					const yyyyMM = cloneDt.format('YYYYMM');
					const yyyy = cloneDt.format('YYYY');
					const mm = cloneDt.format('MM');
					for (let hour = sHH24; hour < eHH24; hour++) {
						const hh = this.lpadZero(hour);
						const pivotField = `${yyyyMM}${hh}00`;
						pivotParam.push(pivotField);
						customColumns.push(this.makeDateColumn(`${hh}:00`, `'${pivotField}'`, `${yyyy}년 ${mm}월`, `${hh}:00 ~ ${hh}:59`));
					}
					cloneDt.add(1, 'month');
				}
			}

			// 커스텀 컬럼으로 변경 작업
			const startCols = this.getColumnsByPosition('start');
			const endCols = this.getColumnsByPosition('end');
			this.gridColumns = [...startCols, ...customColumns, ...endCols];
			this.gridColumnDescs = this.gridColumns;
			return pivotParam;
		},
		lpadZero(num) {
			return num < 10 ? `0${num}` : `${num}`;
		},
		getColumnsByPosition(position) {
			return this.gridColumns.filter(v => v.position === position);
		},
		/**
		 * 보고서 상세 내역 데이터를 출력하는 팝업 열기
		 * <col> 태그 내 -> {format: 'fmLink', linkNm: 'E_IVRSVC'} 태그 필요
		 * @TODO: masterQuery는 템플릿이기 때문에 masterQuery 외 서브로 분리 필요
		 * @param rowData: 그리드 행 데이터
		 * @param linkNm: 마스터쿼리 name
		 */
		async onClickLinkInGrid(rowData, linkNm) {
			const params = rowData;
			// 보고서 조회 날짜 그대로 사용하기 위해 로컬스토리지에서 조회
			const masterQueryType = 'master-query';
			const lsOptionDatas = localStorage.getItem(`ECSS_RPT_${this.reportId}`);
			const { search } = JSON.parse(lsOptionDatas);
			params['dayEnd'] = search.dayEnd;
			params['dayStart'] = search.dayStart;
			params['type'] = masterQueryType;
			params['name'] = linkNm;

			// Column List 조회
			let columnPayload = {
				actionname: 'MASTER_QUERY_COLUMN_LIST',
				data: { params: { type: masterQueryType, name: params['name'] } },
				loading: true,
			};

			const getColumnsRes = await this.CALL_API(columnPayload);
			if (isSuccess(getColumnsRes)) {
				this.modal.historyColumns = getColumnsRes.data.data;
			} else {
				return false;
			}

			// 마스터 쿼리 파일 이용해서 데이터 조회
			let resultSetPayload = {
				actionname: 'MASTER_QUERY_RESULT_LIST',
				data: { data: params },
				loading: true,
			};

			const getResultSetRes = await this.CALL_API(resultSetPayload);
			if (isSuccess(getResultSetRes)) {
				this.modal.historyDatas = getResultSetRes.data.data;
			}
			this.modal.reportRowData = [rowData];
			this.modal.isOpen = true;
		},
		/**
		 * XML에서 정의된 컬럼을 multiHeaderNm 기준으로
		 * 병합하여 반환하는 함수
		 * @param columns
		 * @returns {*[]}
		 */
		getReplaceMultiHeaderColumn(columns) {
			let result = [];
			columns.forEach(col => {
				if (isEmpty(col.multiHeaderNm)) {
					result.push(col);
				} else {
					const preData = result.at(-1);
					if (Array.isArray(preData) && preData.at(-1).multiHeaderNm === col.multiHeaderNm) {
						result.at(-1).push(col);
					} else {
						result.push([col]);
					}
				}
			});
			return result;
		},
		/**
		 * Historical 테이블 타입 조회
		 */
		getHistoricalTableType(param) {
			let resultSuffix = '_'; // 기본 YYYYMM 테이블 결과
			// HH24:MM 변환
			let startTime = param.startTime;
			let endTime = param.endTime;

			// Null 일 시 기본 값
			if (!startTime || !endTime) {
				startTime = '00:00';
				endTime = '24:00';
			}

			startTime = startTime.replace(':', '');
			endTime = endTime.replace(':', '');

			let sHH = startTime.substring(0, 2);
			let sMM = startTime.substring(2, 4);
			let eHH = endTime.substring(0, 2);
			let eMM = endTime.substring(2, 4);

			// 요일 셋팅
			let days = param.days;
			let isSelectedAllDays = false;
			if (days && param.days.length === 7) {
				isSelectedAllDays = true;
			}

			// 검색 컴포넌트 보고서 유형
			const I = 'I';
			const I30 = 'I30';
			const H = 'H';
			const D = 'D';
			const M = 'M';
			// 15분, 30분, 시간 Bool
			let isI15 = sMM === '15' || eMM === '15' || sMM === '45' || eMM === '45';
			let isI30 = sMM === '30' || eMM === '30';
			let isH = sHH !== '00' || eHH !== '24';

			// suffix 찾기
			let reportType = param.report_type;
			switch (reportType) {
				case 'daily': // 일별
					if (isI15) resultSuffix = I;
					else if (isI30) resultSuffix = I30;
					else if (isH) resultSuffix = H;
					else resultSuffix = D;
					break;
				case 'monthly': // 월별
					if (isI15) resultSuffix = I;
					else if (isI30) resultSuffix = I30;
					else if (isH) resultSuffix = H;
					else resultSuffix = isSelectedAllDays ? M : D;
					break;
				case 'hour': // 시간별
					if (isI15) resultSuffix = I;
					else if (isI30) resultSuffix = I30;
					else resultSuffix = H;
					break;
				case 'i30': // 30분별
					if (isI15) resultSuffix = I;
					else resultSuffix = I30;
					break;
				case 'i15': // 15분별
					resultSuffix = I;
					break;
				case 'daytimes': //일+시간별
					if (isI15) resultSuffix = I;
					else if (isI30) resultSuffix = I30;
					else resultSuffix = H;
					break;
				default:
					break;
			}

			return resultSuffix;
		},
		/**
		 * SearchBox>보고서 조회 버튼 클릭 이벤트
		 * @param params
		 * @param hiddenColumns: 숨겨야할 컬럼 리스트(대상 버튼 options -> hiddenColumns:[...])
		 * @returns {Promise<void>}
		 */
		async onSearchClick(params, hiddenColumns) {
			params.menuNm = this.reportInfo.menuNm;
			//대상 버튼 내 라디오버튼이 여러 개일 시 그리드 컬럼 숨김 처리
			const { chkDepthTarget } = this.$refs.grid; // false: 활성화, true: 비활성화
			this.setVisibleGridColumn(chkDepthTarget ? false : hiddenColumns);

			/**
			 * date-column-report 커스텀 부분
			 */
			await this.$nextTick();
			params.tableType = this.getHistoricalTableType(params);
			const pivotDatas = this.getPivotDataAndUpdateColumns(params);
			if (pivotDatas === false) return false;
			params.pivot = pivotDatas;

			//멀티헤더 변경
			this.gridColumns = this.getReplaceMultiHeaderColumn(this.gridColumns);

			//보고서 조회
			this.$refs.grid.showReportResults(params);
		},
		/**
		 * 그리드 컬럼 숨김 처리팅
		 * false, undefined, null, [] 일 시 전체 컬럼 출력
		 * @param hiddenColumns: 대상 버튼 options -> hiddenColumns:[...]
		 *
		 */
		setVisibleGridColumn(hiddenColumns) {
			const allShow = !hiddenColumns; // false, undefined, null, []
			this.gridColumns.forEach(element => {
				element.visible = allShow || !hiddenColumns.includes(element.dataField);
			});
		},
		/**
		 * SearchBox>보고서 조회 기능
		 * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
		 */
		onSearchReport(excelFlag) {
			this.$refs[this.searchBoxComponent].onSearchReport(excelFlag);
		},
		/**
		 * Grid>Toolbar>하위대상기준조회: 체크박스 상태값
		 * @returns {boolean}
		 */
		getChkDepthTarget() {
			return this.$refs.grid.chkDepthTarget;
		},
		/**
		 * 상세 이력 팝업 클릭 이벤트
		 * @param openOrClose: 열기(true) || 닫기(fasle)
		 */
		onClickToggleModal(openOrClose) {
			this.modal.isOpen = openOrClose;
		},
		getSelectedReportFilterKeys() {
			return this.$refs.grid.filter.selectedKeys;
		},
		getReportLocalStorage() {
			return localStorage.getItem(`ECSS_RPT_${this.reportInfo.reportId}`);
		},
		setReportLocalStorage() {
			const data = this.$refs[this.searchBoxComponent].getReportSearchParams();
			localStorage.setItem(`ECSS_RPT_${this.reportInfo.reportId}`, JSON.stringify(data));
		},
	},

	async created() {
		await this.init();
	},
	mounted() {},
	updated() {},
	destroyed() {},
};
</script>
