<template>
	<transition>
		<div class="flex gap-x-8 items-stretch">
			<!-- [START] 좌측 영역 -->
			<div class="w-4/6 editor-style">
				<!-- 헤더 -->
				<div class="flex justify-between">
					<h3 class="text-xl font-medium mt-1.5">
						{{ options.reportNm }} <font size="1.5" v-if="options.reportId !== undefined">({{ options.reportId }}.xml)</font>
					</h3>
					<div class="flex pb-2 space-x-1">
						<DxButton
							v-if="options.reportId === 'master-query'"
							class="btn_XS white light_filled"
							text="컬럼생성"
							@click="appendColTagByQueryWrapper"
						/>
						<DxButton class="btn_XS white light_filled" text="초기화" @click="initXmlEditor" />
						<DxButton class="btn_XS white light_filled" text="검증" @click="validateXmlOfEditor" />
						<DxButton class="btn_XS white light_filled" text="코드정리" @click="prettierEditorData('xml')" />
					</div>
				</div>

				<!-- 에디터 -->
				<div class="vh-65">
					<codemirror-editor ref="xmlEditor" v-model="cmEditor" beautyType="html" />
				</div>
			</div>
			<!-- [END] 좌측 영역 -->

			<!-- [START] 히스토리 및 설명 영역 -->
			<div v-if="options.useRight" class="w-2/6">
				<h3 class="text-xl font-medium mt-1.5">작업 이력</h3>
				<div class="py-2">
					<div>
						<DxDataGrid
							class="grid-box"
							:data-source="history"
							:show-borders="true"
							:show-column-headers="true"
							:show-column-lines="false"
							:show-row-lines="true"
							:height="460"
							:row-alternation-enabled="false"
							:allow-column-reordering="false"
							no-data-text="데이터가 존재하지 않습니다."
							:visible="true"
							key-expr="id"
							:hover-state-enabled="true"
							:selected-row-keys="selectedRowKey"
							@cell-hover-changed="onCellHoverChanged"
							@content-ready="selectFirstRow"
							@selectionChanged="handlerClickRow"
						>
							<DxPager :visible="false" />
							<DxSelection mode="single" />
							<DxColumnFixing :enabled="false" />
							<DxColumn
								caption="작업자"
								data-field="editId"
								alignment="center"
								width="120"
								:fixed="true"
								:visible="true"
								:allow-sorting="false"
							/>
							<DxColumn
								caption="설명"
								data-field="description"
								:fixed="true"
								alignment="left"
								:visible="true"
								:allow-sorting="false"
							/>
							<DxColumn
								caption="작업일시"
								data-field="editDt"
								alignment="center"
								width="120"
								:fixed="true"
								:visible="true"
								:allow-sorting="false"
								cell-template="editDtTemplate"
							/>
							<DxScrolling row-rendering-mode="virtual" />
							<template #editDtTemplate="{ data }">
								{{ formatEditDt(data.value) }}
							</template>
						</DxDataGrid>
						<!-- 설명 팝오버 -->
						<DxPopover
							width="auto"
							position="bottom"
							:target="popover.target"
							:visible="popover.visible"
							:close-on-outside-click="true"
						>
							<p>{{ popover.content }}</p>
						</DxPopover>
					</div>
					<div class="py-2">
						<DxTextArea
							width="100%"
							:height="120"
							:max-length="100"
							class="mar_ri10 alB"
							v-model="description"
							:styling-mode="stylingMode"
							@key-up="keyupDescription($event)"
						/>
						<div class="mt-05 text-end">{{ descriptionLength }}/100 자</div>
					</div>
				</div>
			</div>
			<!-- [END] 히스토리 및 설명 영역 -->
		</div>
	</transition>
</template>
<script>
import CodemirrorEditor from '@/components/codemirror/codemirror-xml-editor.vue';
import { DxButton } from 'devextreme-vue/button';
import { DxPopover } from 'devextreme-vue/popover';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxColumn, DxColumnFixing, DxDataGrid, DxPager, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
import { isSuccess } from '@/plugins/common-lib';
import { vkbeautify } from '@/plugins/vkbeautify';

export default {
	components: {
		DxButton,
		DxPopover,
		DxDataGrid,
		DxTextArea,
		//Gird
		DxPager,
		DxColumn,
		DxScrolling,
		DxColumnFixing,
		DxSelection,
		//Custom
		CodemirrorEditor,
	},
	props: {
		reportId: {
			type: [Number, String],
			default: 0,
		},
		options: {
			type: Object,
			default: () => {
				return {
					type: 'REPORT', // type: ['REPORT', 'MASTER']
					reportNm: '',
					reportId: undefined,
					useRight: false,
					useHeader: false,
					useCreateCols: false,
				};
			},
		},
		history: {
			type: Array,
			default: () => [],
		},
	},
	watch: {},
	data() {
		return {
			isInit: true,
			cmEditor: null,
			description: '',
			selectedHistoryId: null,
			selectedRowKey: null,
			stylingMode: 'outlined', //[outlined, filled, underlined]
			popover: {
				target: null,
				content: '',
				visible: false,
			},
		};
	},
	computed: {
		descriptionLength() {
			if ([undefined, null].includes(this.description)) return 0;
			return this.description.length;
		},
	},
	methods: {
		clearVueData() {
			this.isInit = true;
			this.cmEditor = null;
			this.description = '';
			this.selectedHistoryId = null;
			this.selectedRowKey = null;
			this.popover = {
				target: null,
				content: '',
				visible: false,
			};
		},
		formatEditDt(data) {
			const ret = this.$_commonlib.formatDate(data, 'YYYYMMDDHHmmssSSS', 'YY.MM.DD HH:mm');
			if ('Invalid date' !== ret) {
				return ret;
			}
			return data;
		},
		getXmlData(data) {
			// TODO: 보고서, 마스터쿼리 컬럼 달라서 생긴 문제로 임시 생성. 변경(통일) 필요
			return data.xmlData || data.sqlXml; // 보고서: xmlData | 공통: sqlXml;
		},
		setHistoryId(id) {
			this.selectedHistoryId = id;
		},
		keyupDescription(e) {
			this.description = e.event.currentTarget.value;
		},
		setDescription(description) {
			this.description = description;
		},
		async handlerClickRow({ selectedRowsData }) {
			if (selectedRowsData.length === 0) return;
			const { id, description } = selectedRowsData[0];
			const xmlData = this.getXmlData(selectedRowsData[0]);
			if (xmlData) {
				const vm = this;
				this.setHistoryId(id);
				this.setDescription(description || '');

				// Code Mirror UI 깨짐 방지위해 setTimeout 사용
				if (this.isInit) {
					this.isInit = false;
					setTimeout(() => (vm.cmEditor = xmlData), 250);
				} else {
					vm.cmEditor = xmlData;
				}
			}
		},
		async validateXmlOfEditor() {
			const { type } = this.options;
			const validatePageDataRet = this.validatePageData();
			if (validatePageDataRet.isError) {
				this.$_Msg(validatePageDataRet.msg);
				return false;
			}

			// set Param
			let actionname = 'REPORT_XML_VALIDATION';
			const params = {};
			if ('REPORT' === type) {
				params.reportId = this.reportId;
				params.xmlData = this.cmEditor;
			} else if ('MASTER' === type) {
				actionname = 'MASTER_QUERY_VALIDATION';
				params.name = this.options.masterNm;
				params.sqlXml = this.cmEditor; //TODO: xmlData 로 변경 필요 (DB 컬럼 변경 후)
			}

			const res = await this.CALL_API({
				actionname,
				data: params,
				loading: true,
			});

			if (isSuccess(res)) this.$_Msg('검증 완료되었습니다.');
		},
		initXmlEditor() {
			const data = this.history.find(v => v.id === this.selectedHistoryId);
			if (data) this.cmEditor = this.getXmlData(data);
			else this.cmEditor = '';
		},
		selectFirstRow(e) {
			console.log('selectFirstRow:', e);
			const rowKey = e.component.getKeyByRowIndex(0);
			this.selectedRowKey = [rowKey];
			if (rowKey) {
				e.component.getDataSource(0)._items[0].editDt = `사용중`;
			}
		},
		onCellHoverChanged(e) {
			this.popover.visible = false;
			if (e.rowType === 'data' && e.eventType === 'mouseover') {
				const rowData = this.history.find(v => v.id === e.data.id);
				if (rowData && rowData.description) {
					this.popover.visible = true;
					this.popover.target = e.cellElement;
					this.popover.content = rowData.description;
				}
			}
		},
		//Master Query 때문에 추가
		setCmEditor(data) {
			const vm = this;
			setTimeout(() => (vm.cmEditor = data), 250);
		},
		makeColTag(name) {
			const col = [];
			col.push(`<col id="${name}">`);
			col.push(`    <caption>${name}</caption>`);
			col.push(`    <dataField>${name}</dataField>`);
			col.push(`    <format>fmString</format>`);
			col.push(`    <width>120</width>`);
			col.push(`    <fixed>false</fixed>`);
			col.push(`    <visible>true</visible>`);
			col.push(`    <align>center</align>`);
			col.push(`    <description> </description>`);
			col.push(`</col>`);
			return col;
		},
		validatePageData() {
			const parser = new DOMParser();
			const xmlData = parser.parseFromString(this.cmEditor, 'application/xml');
			let pageDataElem = xmlData.getElementsByTagName('pagedata')[0];
			if (!pageDataElem) {
				pageDataElem = xmlData.getElementsByTagName('pageData')[0];
			}
			const ret = { isError: true, msg: '' };
			if (!pageDataElem) {
				ret.msg = '&lt;pagedata&gt; 태그가 존재하지 않습니다.';
				return ret;
			}

			const pageDataValue = pageDataElem.textContent;
			if (pageDataValue === '') {
				ret.msg = '&lt;pagedata&gt; 태그 내 값이 존재하지 않습니다.';
				return ret;
			}

			try {
				new Function(`return ${pageDataValue.trim()}`);
			} catch (e) {
				ret.msg = `&lt;pagedata&gt; format을 확인해주시기 바랍니다.\n${e}`;
				return ret;
			}

			ret.isError = false;
			return ret;
		},
		/*
		 * @description: '<query_wrapper>' 태그를 내 출력될 컬럼을 이용해서 default cols 태그 생성
		 * */
		appendColTagByQueryWrapper() {
			const parser = new DOMParser();
			const xmlData = parser.parseFromString(this.cmEditor, 'application/xml');
			const queryWrapperElem = xmlData.getElementsByTagName('query_wrapper')[0];
			if (!queryWrapperElem) {
				this.$_Msg(`<query_wrapper> 태그를 확인해주시기 바랍니다.`);
				return;
			}

			const regex = /\([^()]*\)/g;
			let sqlStr = queryWrapperElem.textContent;
			sqlStr = vkbeautify.sql(sqlStr);
			while (sqlStr.indexOf('(') > -1) {
				sqlStr = sqlStr.replace(regex, '');
			}

			sqlStr = sqlStr.toUpperCase();
			const startIndex = sqlStr.indexOf('SELECT') + 6;
			const splitDatas = sqlStr.substring(startIndex, sqlStr.lastIndexOf('FROM')).split(',');
			const columns = splitDatas.reduce((acc, v) => {
				let str = v.trim();
				const data = str.split(' ');
				const col = data[data.length - 1].trim();
				acc.push(col);
				return acc;
			}, []);

			const cols = [];
			const colsTag = xmlData.getElementsByTagName('cols')[0];
			columns.forEach(name => cols.push(this.makeColTag(name).join('\n')));
			if (colsTag) {
				colsTag.innerHTML = cols.join('');
			} else {
				cols.unshift('<cols>');
				cols.push('</cols>');
				const colsDocElem = parser.parseFromString(cols.join(''), 'application/xml').documentElement;
				xmlData.getElementsByTagName('sql')[0].appendChild(colsDocElem);
			}

			try {
				this.cmEditor = new XMLSerializer().serializeToString(xmlData);
			} catch (e) {
				console.log(e);
			}
		},
		prettierEditorData(type) {
			try {
				this.cmEditor = vkbeautify[type](this.cmEditor)
					.split('\n')
					.filter(v => v.trim() !== '')
					.join('\n');
			} catch (e) {
				console.log(e);
			}
		},
	},
	created() {},
	mounted() {},
	destroyed() {},
};
</script>
<style scoped>
.mt-05 {
	margin-top: 5px;
}

.text-end {
	text-align: end;
}
.vh-65 {
	height: 65vh;
}
</style>
