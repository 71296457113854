//탭/검색/페이지 이력 데이터 관리

// - tabHists : 탭 인덱스 관련 관리
/*****
{ routerPath : tabIndex }
ex) { '/op/surveys/list' :  0 }
*****/


// - searchHists : 검색 조건 관련 관리
/*****
1. 검색 이력(탭이 없을 경우)
{ 
    routerPath : [
        {
            search: paramsData
        }
    ] 
}
ex) 
{
    '/op/surveys/list' : [
        { 
            search: paramsData 
        }
    ]
}
2. 검색 이력(탭이 있을 경우)
{
    routerPath: [
        { tab: tabIndex1, search: paramsData },
        { tab: tabIndex2, search: paramsData },
    ]
}
ex)
{
    '/op/surveys/list': [
        { tab: 0, search: paramsData },
        { tab: 1, search: paramsData },
    ]
}
*****/

// - pageHists : 해당 페이지 관련 데이터 관리
/*****
 {
    routerPath: {
        {   key : value }
    }
 }

 ex)
 {
    '/op/dnis/list':{
        {   searchTypeChannelTypes: arrays  }
    }
 }
*/

// - pagingHists : paging 관련 관리
/*****
 {
    routerPath: [
        {
            pageSize: size,
            pageIndex: index,
        }
    ]
 }

 ex)
 {
    '/op/account/list': [
        {
            pageSize: 10,
            pageIndex: 0
        }
    ]
 }
 */

const initialState = {     //탭/검색 이력 데이터 관리
    tabHists: {},           // 탭 관련 이력(수정한 뒤 해당 탭으로 이동)
    listCacheFl: null,      // 목록 캐시 여부(검색과 페이지목록수옵션 캐시저장 관련 처리를 위해 필요)
    searchHists: {},        // 검색 관련 이력(시스템설정의 검색캐시저장여부(search_cache_fl)에 따라 데이터 유지)
    pagingHists: {},        // 페이지목록수옵션(pageSize, pageIndex) 관련 이력, 시스템설정의 페이지목록수옵션캐시저장여부(page_size_cache_fl)에 따라 데이터 유지
    previousPath: null,     // 이전페이지 path
    listParams: null,       // 목록페이지 데이터 관리
    detailParams: null,     // 상세페이지 데이터 관리, getParams -> getDetailParams로 변경
    pageHists: {},          // listParams와 detailParams로 세분화 관리하여 삭제 예정
    params: null,           // 삭제 예정
    permissionList: null,   // 권한 리스트
    accessToken: null,      // 액세스 토큰
};

const state = {     //탭/검색 이력 데이터 관리
    ...initialState
};

const getters = {
    getPreviousPath: state => state.previousPath,       // 이전페이지 path
    getTabHists: state => state.tabHists,               // 탭 관련 이력
    getListCacheFl: state => state.listCacheFl,         // 목록 캐시 여부
    getSearchHists: state => state.searchHists,         // 검색 이력
    getPagingHists: state => state.pagingHists,         // 페이지 목록 수 옵션 관련 이력
    getPageHists: state => state.pageHists,             // 페이지 관련 이력
    getParams: state => state.params,                   // FIXME: 삭제 예정(확인 요망)
    getListParams: state => state.listParams,           // 목록페이지 데이터 관련
    getDetailParams: state => state.detailParams,       // 상세페이지 데이터 관련, getParams -> getDetailParams로 변경
    getPermissionList: state => {
        return state && state.permissionList ? state.permissionList : [];
    }, // 권한 리스트
    getPersistedAccessToken: state => state.accessToken,
};

const mutations = {
    setPreviousPath(state, data) {
        state.previousPath = data;
    },
    setTabHists(state, data) {
        state.tabHists = data;
    },
    setListCacheFl(state, data) {
        state.listCacheFl = data;
    },
    setSearchHists(state, data) {
        state.searchHists = data;
    },
    setPagingHists(state, data) {
        state.pagingHists = data;
    },
    setPageHists(state, data) {
        state.pageHists = data;
    },
    setParams(state, data) {
        state.params = data;
    },
    setListParams(state, data) {
        state.listParams = data;
    },
    setDetailParams(state, data) {
        state.detailParams = data;
    },
    setPermissionList(state, payload) {
        state.permissionList = payload
    },
    resetSessionState(state) {
        Object.assign(state, initialState);
    },
    setPersistedAccessToken(state, payload) {
        state.accessToken = payload;
    }
};

const actions = {
    setPreviousPath({ commit }, data) {
        commit('setPreviousPath', data);
    },
    setTabHists({ commit }, data) {
        commit('setTabHists', data);
    },
    setListCacheFl({ commit }, data) {
        commit('setListCacheFl', data);
    },
    setSearchHists({ commit }, data) {
        commit('setSearchHists', data);
    },
    setPagingHists({ commit }, data) {
        commit('setPagingHists', data);
    },
    setPageHists({ commit }, data) {
        commit('setPageHists', data);
    },
    setParams({ commit }, data) {
        commit('setParams', data);
    },
    setListParams({ commit }, data) {
        commit('setListParams', data);
    },
    setDetailParams({ commit }, data) {
        commit('setDetailParams', data);
    },
    setPermissionList({ commit }, data) {
        commit('setPermissionList', data);
    },
    resetSessionState(state) {
        Object.assign(state, initialState);
    },
    setPersistedAccessToken({ commit }, data) {
        commit('setPersistedAccessToken', data);
    }
};

export default {
    strict: process.env.NODE_ENV !== "production",
    state: {
        ...state
    },
    getters,
    mutations,
    actions
};
