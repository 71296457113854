<!--
  PACKAGE_NAME : src/components/report/searchbox/condition/popover-search-condition.vue
  FILE_NAME : popover-search-condition.vue
  AUTHOR : vnemftnsska2
  DATE : 2024/07/09
  DESCRIPTION : 보고서 검색 컴포넌트 내 검색조건 팝오버
-->
<template>
  <div class="report-search">
    <DxPopover
      :visible.sync="condition.isShow"
      show-event="click"
      target="#popoverSearchConditionBtn"
      :width="350"
      :position="{ my: 'bottom', offset: '-125 -22' }"
      :onShowing="onShowingSearchCondition"
    >
      <div class="p-8">
        <DxButton
          text="검색조건 추가"
          class="btn_XS default filled add"
          @click="toggleAddModal(true)"
          style="margin: 0; display: block !important"
        />
      </div>
      <div style="height: 300px">
        <DxDataGrid
          id="search-condition-grid"
          key-expr="id"
          :height="300"
          :data-source="condition.list"
          :show-borders="false"
          :show-column-lines="false"
          :show-column-headers="false"
          :show-row-lines="true"
          :column-auto-width="true"
          :hover-state-enabled="true"
          @cell-prepared="onCellPreparedStylingGrid"
          @cell-click="onClickSearchCondition"
          no-data-text="검색 조건이 존재하지 않습니다."
        >
          <DxScrolling mode="virtual" />
          <DxColumn data-field="title" />
          <DxColumn :width="50" cell-template="deleteTemplate" />
          <template #deleteTemplate="{ data }">
            <button class="btn-icon close" type="button" @click="handleClickSearchConditionDelete(data)" />
          </template>
        </DxDataGrid>
      </div>
    </DxPopover>

    <!-- START: 검색조건관리 저장 팝업 -->
    <DxPopup
      :option="{
        title: '검색조건 추가',
        width: '480',
        height: '260',
        minWidth: null,
        minHeight: null,
      }"
      :isOpen="addModal.isShow"
      @closeModal="toggleAddModal(false)"
      @saveModal="saveSearchCondition"
    >
      <template #content>
        <ModalContent ref="add-modal-content" />
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import { DxDataGrid, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxButton } from 'devextreme-vue/button';
  import { getResData, isSuccess } from '@/plugins/common-lib';
  import DxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import ModalContent from '@/components/report/searchbox/condition/modal-add-content.vue';

  export default {
    components: { ModalContent, DxPopup, DxButton, DxScrolling, DxPopover, DxDataGrid, DxColumn },
    props: {
      /** 보고서 정보 */
      info: {
        type: Object,
        default: () => ({}),
      },
      /** 보고서 검색조건에 셋팅된 파라미터를 가지고 오는 메서드 */
      getReportSearchParams: {
        type: Function,
        default: () => {},
      },
      /** 조회 조건 셋팅 */
      onClickSearchCondition: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        PAGE_DATA: {
          // 메뉴 페이지 데이터 설정
          pageDto: {
            pagesize: 1000, // 기본 페이지 사이즈 설정(메뉴설정>PageData 수정으로 변경가능)
          },
        },
        // 팝오버 값
        condition: {
          list: [], // 검색조건 리스트
          isShow: false, // 팝오버 토글
          loading: false,
        },
        // 검색조건 추가 모달
        addModal: {
          isShow: false,
        },
      };
    },
    watch: {
      info: {
        handler(newValue) {
          if (Object.hasOwn(newValue, 'menuId')) {
            this.asyncGetSearchCondition();
          }
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {},
    methods: {
      /** 검색 조건 팝오버 토글 (외부에서 사용할 것) */
      toggleConditionPopover(bool) {
        this.condition.isShow = bool;
      },
      /** 검색조건 추가 모달 토글 */
      toggleAddModal(bool) {
        this.addModal.isShow = bool;
      },
      /** 버튼 클릭 시 노출될 때 검색조건 리스트를 가지고 오는 API 실행 트리거 */
      async onShowingSearchCondition() {
        // await this.asyncGetSearchCondition();
      },
      /** 검색조건 리스트를 가지고 오는 API 실행 */
      async asyncGetSearchCondition() {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_SEARCH_PARAM_LIST',
          data: {
            menuId: this.info.menuId,
            memberId: this.$store.getters.getLoginId,
          },
          loading: false,
        });

        if (isSuccess(res)) {
          this.condition.list = getResData(res);
        }
      },
      /** 그리드 스타일링 */
      onCellPreparedStylingGrid(dxEvent) {
        if (dxEvent.columnIndex > 0) return;
        dxEvent.cellElement.classList.add('condition-row');
      },
      /** 검색조건 삭제 */
      async handleClickSearchConditionDelete({ data }) {
        if (data && data.id) {
          const res = await this.CALL_REPORT_API({
            actionname: 'REPORT_SEARCH_PARAM_DELETE',
            path: `/${data.id}`,
          });

          const toastMessage = isSuccess(res) ? '검색 조건이 삭제되었습니다.' : '작업실패';
          this.$_Toast(toastMessage);

          // 다시 조회
          await this.asyncGetSearchCondition();
        }
      },
      /** 검색조건 추가 API 호출 */
      async saveSearchCondition() {
        const addModalContent = this.$refs['add-modal-content'];
        const { title, limitSize } = addModalContent.getTitleAndLimitSize();

        const selectedReportParams = this.getReportSearchParams();
        if (title !== '' && title.length < limitSize) {
          const res = await this.CALL_REPORT_API({
            actionname: 'REPORT_SEARCH_PARAM_INSERT',
            data: {
              title: title,
              menuId: this.info.menuId,
              param: JSON.stringify(selectedReportParams),
            },
            loading: true,
          });

          this.$_Toast(isSuccess(res) ? '검색 조건이 저장되었습니다.' : '작업실패');
          if (isSuccess(res)) {
            this.$refs['add-modal-content'].init();
            this.toggleAddModal(false);
            // 다시 조회
            await this.asyncGetSearchCondition();
          }
        } else {
          const validMsg = title === '' ? '조건검색명은 필수입니다.' : '50자 이하로 작성해주시기 바랍니다.';
          this.$_Toast(validMsg);
        }
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .p-8 {
    padding: 8px;
  }
  .my-popover-class {
    border: 1px solid #999 !important;
  }
</style>
<style>
  \.dx-tag-content {
    padding: 3px 30px 3px 8px !important;
  }
  #search-condition-grid .dx-state-hover > .condition-row:hover {
    color: #6ab0b2 !important;
    cursor: pointer !important;
  }
</style>
