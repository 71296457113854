<template>
  <div class="page-title lin1">
    <h1 :style="isMenuDepth3 ? 'float: left;' : ''">{{ currentMenu.menuNm }}</h1>
    <div v-if="isMenuDepth3">
      <button v-if="isFavoriteMenu" class="rounded-button on-star" @click="deleteFavorite"></button>
      <button v-else class="rounded-button off-star" @click="saveFavorite"></button>
    </div>
    <div class="page-text-map-group fr" v-if="!hidingMenuGroup">
      <span>홈</span>
      <span>{{ dept1Menu.menuNm }}</span>
      <span>{{ dept2Menu.menuNm }} </span>
      <span>{{ dept3Menu.menuNm }} </span>
      <span v-if="dept4Menu">{{ dept4Menu.menuNm }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {
      menuId() {
        return parseInt(this.$route.params.menuid || this.$route.name) || 0;
      },
      currentMenu() {
        const defaultMenu = {
          menuNm: '종합현황',
          menuDepth: 1,
        };

        if (this.menuId === 0) {
          return defaultMenu;
        }

        const menu = this.$store.getters.getMenuList.find(menu => menu.id === this.menuId);
        this.$_getSystemData()
        return {
          menuNm: menu.menuNm,
          menuDepth: menu.menuDepth,
          parentId: menu.parentId,
        };
      },
      hidingMenuGroup() {
        return this.menuId === 0;
      },
      dept4Menu() {
        return this.currentMenu.menuDepth === 4 ? this.currentMenu : null;
      },
      dept3Menu() {
        return this.dept4Menu ? this.findMenuById(this.dept4Menu.parentId) : this.currentMenu;
      },
      dept2Menu() {
        return this.findMenuById(this.dept3Menu.parentId);
      },
      dept1Menu() {
        return this.findMenuById(this.dept2Menu.parentId);
      },
      isMenuDepth3() {
        return this.currentMenu.menuDepth === 3;
      },
      isFavoriteMenu() {
        return this.$store.getters.getFavorite.some(d => d.menuId === this.menuId);
      },
    },
    methods: {
      findMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      async deleteFavorite() {
        const payload = {
          actionname: 'MENU_FAVORITE_DELETE',
          data: {
            data: [
              {
                menuId: this.menuId,
              },
            ],
          },
          useErrorPopup: true,
        };
        await this.toggleFavorite(payload);
      },
      async saveFavorite() {
        const payload = {
          actionname: 'MENU_FAVORITE_SAVE',
          data: [{ menuId: this.menuId }],
          loading: true,
          useErrorPopup: true,
        };
        await this.toggleFavorite(payload);
      },
      async toggleFavorite(payload) {
        await this.CALL_API(payload);
        this.$_eventbus.$emit('getFavoriteMenu');
      },
    },
  };
</script>

<style scoped>
  .rounded-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    outline: none;
    border: none;
    top: 5px;
    left: -5px;
  }

  .rounded-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
</style>