<template>
  <div class="container" id="container">
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <component
          :ref="searchBoxComponent"
          :newPopupUrl="url"
          :reportInfo="reportInfo"
          :pageLoaded="normalized"
          :is="searchBoxComponent"
          :getChkDepthTarget="getChkDepthTarget"
          :getSelectedReportFilterKeys="getSelectedReportFilterKeys"
          @onSearchClick="onSearchClick"
        />
        <reportGrid
          ref="grid"
          :is="gridComponent"
          :pageData="pageData"
          :columns="gridColumns"
          :pageLoaded="normalized"
          :reportInfo="reportInfo"
          :columnsDesc="gridColumnDescs"
          :getReportLocalStorage="getReportLocalStorage"
          @setReportLocalStorage="setReportLocalStorage"
          @onSearchReport="onSearchReport"
          @onClickLinkInGrid="onClickLinkInGrid"
          @onClickApiDataInGrid="onClickApiDataInGrid"
        />
      </div>

      <DxPopup
        :option="{
          title: '상세 이력',
          width: '1400',
          height: '550',
          minWidth: null,
          minHeight: null,
          useSaveBtn: false,
        }"
        :isOpen="modal.isOpen"
        :toolbarYn="false"
        @closeModal="onClickToggleModal(false)"
      >
        <template #content>
          <HistoryPopupContent
            ref="historyPopupContent"
            :targetGridColumns="gridColumns"
            :originColumns="gridColumnDescs"
            :reportRowData="modal.reportRowData"
            :historyColumns="modal.historyColumns"
            :historyDatas="modal.historyDatas"
          />
        </template>
      </DxPopup>

      <!-- IVR LOG 모달 -->
      <DxPopup
        :option="{
          title: logModal.title,
          width: '1400',
          height: '600',
          minWidth: null,
          minHeight: null,
          useSaveBtn: false,
        }"
        :isOpen="logModal.isOpen"
        :toolbarYn="false"
        @closeModal="onClickToggleLogModal(false)"
      >
        <template #content>
          <LogPopupContent
            ref="LogPopupContent"
            :targetGridColumns="gridColumns"
            :reportRowData="logModal.reportRowData"
            :log="logModal.value"
          />
        </template>
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import ReportSearchBox from '@/components/report/search-box';
  import ReportGrid from '@/components/report/datareport-grid.vue';
  import { getResData, isEmpty, isSuccess } from '@/plugins/common-lib';
  import DxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import HistoryPopupContent from '@/components/report/popup/history-popup-content.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update.vue';
  import LogPopupContent from '@/components/report/popup/log-popup-content.vue';
  export default {
    components: {
      LogPopupContent,
      XmlEditor,
      DxPopup,
      ReportSearchBox,
      ReportGrid,
      HistoryPopupContent,
    },
    props: {},
    watch: {
      /* 동일한 컴포넌트로 이동시 페이지의 기본 변수 초기화 */
      '$route.path': async function () {
        console.log('** watch[wizard-report-view] -> $route.path');
        this.searchBoxComponent = ''; //검색조건 컴포넌트를 바인딩 제거 후 데이터 새로 세팅 후 재 바인딩
        this.gridComponent = '';
        this.pageLoaded = false;
        this.reportId = this.$route.params.menuid;
        await this.init();
        this.searchBoxComponent = 'ReportSearchBox';
        this.gridComponent = 'ReportGrid';
      },
    },
    data() {
      return {
        url: '',
        reportId: -1,
        reportNm: '',
        reportInfo: undefined,
        //Util
        pageLoaded: false, // 페이지 갱신 여부
        //SearchComponent
        searchBoxComponent: 'ReportSearchBox', //검색조건창의 컴포넌트명
        pageData: '', //보고서 XML 파일 내 <pageData> 태그의 데이터
        //GridComponent
        gridComponent: 'ReportGrid',
        gridColumns: [], //컬럼 리스트
        gridColumnDescs: [], //컬럼 설명 리스트
        //historyModal
        modal: {
          isOpen: false,
          reportRowData: [],
          historyColumns: [],
          historyDatas: [],
        },
        // log
        logModal: {
          isOpen: false,
          value: '',
          title: 'LOG',
          reportRowData: [],
        },
      };
    },
    computed: {
      normalized() {
        return this.pageLoaded;
      },
    },
    methods: {
      /** 보고서 페이지 및 컴포넌트 초기화 */
      async init() {
        let res = await this.CALL_REPORT_API({
          actionname: 'REPORT_MENU_INFO',
          data: { menuId: Number(this.$route.params.menuid) },
          loading: true,
        });

        if (isSuccess(res)) {
          const resData = getResData(res)[0];
          this.reportNm = resData.menuNm;
          this.reportId = resData.reportId;
          this.pageData = resData.pageData;
          const columns = resData.columns.filter(col => col.visible);
          this.gridColumns = columns;
          this.gridColumnDescs = columns;
          this.$set(this, 'reportInfo', resData);
        } else {
          this.$_Msg('보고서 정보를 찾지 못 했습니다.');
          return false;
        }

        // 그리드 멀티헤더 컬럼 처리
        this.gridColumns = this.getReplaceMultiHeaderColumn(this.gridColumns);
        // 새창조회 시 사용될 URL(커스텀 보고서인 경우 ID가 존재하지 않아 URL로 처리)
        this.url = this.$route.params.menuid || this.$route.fullPath;
        // 컴포넌트(SearchBox, Grid) 갱신
        this.pageLoaded = true;
      },
      getPageData() {
        return this.$refs.ReportSearchBox.PAGE_DATA;
      },
      /**
       * 보고서 상세 내역 데이터를 출력하는 팝업 열기
       * <col> 태그 내 -> {format: 'fmLink', linkNm: 'E_IVRSVC'} 태그 필요
       * @TODO: masterQuery는 템플릿이기 때문에 masterQuery 외 서브로 분리 필요
       * @TODO: 태그가 아닌 value 속성으로 변경하면 사용하기 더 편할 거 같음
       * @param rowData: 그리드 행 데이터
       * @param linkNm: 마스터쿼리 name
       */
      async onClickLinkInGrid(rowData, linkNm) {
        const params = rowData;
        // 보고서 조회 날짜 그대로 사용하기 위해 로컬스토리지에서 조회
        const lsOptionDatas = localStorage.getItem(`ECSS_RPT_${this.reportId}`);
        const xmlFileType = 'wizard-template';
        const { solution, subPath } = this.reportInfo;
        const { search } = JSON.parse(lsOptionDatas);
        params['name'] = linkNm;

        // Column List 조회
        let columnPayload = {
          actionname: 'MASTER_QUERY_COLUMN_LIST',
          data: { type: xmlFileType, name: params['name'], solution, subPath },
          loading: true,
        };

        const getColumnsRes = await this.CALL_REPORT_API(columnPayload);
        if (isSuccess(getColumnsRes)) {
          this.modal.historyColumns = getResData(getColumnsRes);
        } else {
          return false;
        }

        // 마스터 쿼리 파일 이용해서 데이터 조회
        params['solution'] = solution;
        params['subPath'] = subPath;
        params['type'] = xmlFileType;
        params['dayEnd'] = search.dayEnd;
        params['dayStart'] = search.dayStart;
        const resultSetPayload = {
          actionname: 'MASTER_QUERY_RESULT_LIST',
          data: { data: params },
          loading: true,
        };

        const getResultSetRes = await this.CALL_REPORT_API(resultSetPayload);
        if (isSuccess(getResultSetRes)) {
          this.modal.historyDatas = getResultSetRes.data.data;
        }
        this.modal.reportRowData = [rowData];
        this.modal.isOpen = true;
      },
      /**
       * 보고서 상세 내역 데이터를 출력하는 팝업 열기
       * <col> 태그 내 -> {format: 'api', actionNm, param} 태그 필요
       * @param rowData: 그리드 행 데이터
       * @param linkNm: 마스터쿼리 name
       */
      async onClickApiDataInGrid(title, rowData, log) {
        this.logModal.title = title;
        this.logModal.value = log;
        this.logModal.reportRowData = [rowData];
        this.logModal.isOpen = true;
      },
      /**
       * XML에서 정의된 컬럼을 multiHeaderNm 기준으로
       * 병합하여 반환하는 함수
       * @param columns
       * @returns {*[]}
       */
      getReplaceMultiHeaderColumn(columns) {
        let result = [];
        columns.forEach(col => {
          if (isEmpty(col.multiHeaderNm)) {
            result.push(col);
          } else {
            const preData = result.at(-1);
            if (Array.isArray(preData) && preData.at(-1).multiHeaderNm === col.multiHeaderNm) {
              result.at(-1).push(col);
            } else {
              result.push([col]);
            }
          }
        });
        return result;
      },
      /**
       * SearchBox>보고서 조회 버튼 클릭 이벤트
       * @param params
       * @param hiddenColumns: 숨겨야할 컬럼 리스트(대상 버튼 options -> hiddenColumns:[...])
       * @returns {Promise<void>}
       */
      async onSearchClick(params, hiddenColumns) {
        params.menuNm = this.reportInfo.menuNm;
        params.hiddenColumns = hiddenColumns;
        //대상 버튼 내 라디오버튼이 여러 개일 시 그리드 컬럼 숨김 처리
        const { chkDepthTarget } = this.$refs.grid; // false: 활성화, true: 비활성화
        this.setVisibleGridColumn(chkDepthTarget ? false : hiddenColumns);

        //보고서 조회
        await this.$refs.grid.showReportResults(params);
      },
      /**
       * 그리드 컬럼 숨김 처리팅
       * false, undefined, null, [] 일 시 전체 컬럼 출력
       * @param hiddenColumns: 대상 버튼 options -> hiddenColumns:[...]
       *
       */
      setVisibleGridColumn(hiddenColumns) {
        const allShow = !hiddenColumns; // false, undefined, null, []
        this.gridColumns.forEach(element => {
          element.visible = allShow || !hiddenColumns.includes(element.dataField);
        });
      },
      /**
       * SearchBox>보고서 조회 기능
       * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
       */
      onSearchReport(excelFlag) {
        this.$refs[this.searchBoxComponent].onSearchReport(excelFlag);
      },
      /**
       * Grid>Toolbar>하위대상기준조회: 체크박스 상태값
       * @returns {boolean}
       */
      getChkDepthTarget() {
        return this.$refs.grid.chkDepthTarget;
      },
      /** 상세 이력 팝업 클릭 이벤트 (열기: true || 닫기: false */
      onClickToggleModal(bool) {
        this.modal.isOpen = bool;
      },
      /** 상세 이력 팝업 클릭 이벤트 (열기: true || 닫기: false) */
      onClickToggleLogModal(bool) {
        this.logModal.isOpen = bool;
      },
      getSelectedReportFilterKeys() {
        return this.$refs.grid.filter.selectedKeys;
      },
      getReportLocalStorage() {
        return localStorage.getItem(`ECSS_RPT_${this.reportInfo.reportId}`);
      },
      setReportLocalStorage() {
        const data = this.$refs[this.searchBoxComponent].getReportSearchParams();
        localStorage.setItem(`ECSS_RPT_${this.reportInfo.reportId}`, JSON.stringify(data));
      },
    },

    async created() {
      await this.init();
    },
    mounted() {},
    updated() {},
    destroyed() {},
  };
</script>
<style>
  .test {
  }
</style>
